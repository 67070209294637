import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Component } from 'react';


import NotFound from './pages/notFound';

import Login from './pages/security/login'
import UnauthorizedPage from "./pages/security/unauthorizedPage"

import Profile from './pages/profile';

import AddProducts from './pages/m-energies-solutions/addProducts';
import ListProducts from './pages/m-energies-solutions/listProducts';
import ListProductsOf from './pages/m-energies-solutions/listProductsOf';
import EditProduct from './pages/m-energies-solutions/editProduct';
import MaintenanceContract from './pages/m-energies-solutions/maintenanceContract';

import ListAgences from './pages/agences/listAgences';
import DetailAgences from './pages/agences/detailAgences';
import NewAgences from './pages/agences/newAgences';
import Secteur from './pages/agences/secteur';

import Partenaire from './pages/partenaire/partenaire';

import Integration from './pages/integration/integration';

import Home from './pages/home';

import { RequireAuth, Logout } from "moduleauthazureapp"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="*" exact element={<NotFound />} />
          <Route path="/404" exact element={<NotFound />} />
          <Route path="/unauthorizedPage" exact element={<UnauthorizedPage />} />
          <Route path="/logout" exact element={<Logout />} />
          <Route path="/" exact element={<Login />} />

          <Route path="/profile" exact
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_ECOMM:superadministrateur',
                'ROLE_ECOMM:administrateur',
                'ROLE_ECOMM:modificateur',
                'ROLE_ECOMM:lecteur'
              ]}>
              <Profile />
            </RequireAuth>} />

          <Route path="/home" exact element={<RequireAuth ArrayRoles={["ROLE_WEBADMIN"]}><Home /></RequireAuth>} />

          //M-energies solutions
          <Route path="/tableau-des-produits/:categoryProduct"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_ECOMM:superadministrateur',
                'ROLE_ECOMM:administrateur',
                'ROLE_ECOMM:modificateur',
                'ROLE_ECOMM:lecteur'
              ]}>
              <ListProductsOf />
            </RequireAuth>} />

          <Route path="/tableau-des-produits"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_ECOMM:superadministrateur',
                'ROLE_ECOMM:administrateur',
                'ROLE_ECOMM:modificateur',
                'ROLE_ECOMM:lecteur'
              ]}>
              <ListProducts />
            </RequireAuth>} />

          <Route path="/nouveau-produit/:categoryProduct"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_ECOMM:superadministrateur',
                'ROLE_ECOMM:administrateur',
                'ROLE_ECOMM:modificateur',
              ]}>
              <AddProducts />
            </RequireAuth>} />

          <Route path="/edit-produits/:categoryProduct/:idProduct"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_ECOMM:superadministrateur',
                'ROLE_ECOMM:administrateur',
                'ROLE_ECOMM:modificateur',
              ]}>
              <EditProduct />
            </RequireAuth>} />

          <Route path="/contrat-entretien/"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_ECOMM:superadministrateur',
                'ROLE_ECOMM:administrateur',
                'ROLE_ECOMM:modificateur',
                'ROLE_ECOMM:lecteur'
              ]}>
              <MaintenanceContract />
            </RequireAuth>} />


           //Gestion agence

          <Route path="/tableau-des-agences"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_AGENCE:superadministrateur',
                'ROLE_AGENCE:administrateur',
                'ROLE_AGENCE:modificateur',
                'ROLE_AGENCE:lecteur'
              ]}>
              <ListAgences />
            </RequireAuth>} />

          <Route path="/detail-agence/:idAgence"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_AGENCE:superadministrateur',
                'ROLE_AGENCE:administrateur',
                'ROLE_AGENCE:modificateur',
                'ROLE_AGENCE:lecteur'
              ]}>
              <DetailAgences />

            </RequireAuth>} />
          <Route path="/nouvelle-agence"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_AGENCE:superadministrateur',
                'ROLE_AGENCE:administrateur',
                'ROLE_AGENCE:modificateur'
              ]}>
              <NewAgences />
            </RequireAuth>} />

          <Route path="/secteur"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_AGENCE:superadministrateur',
                'ROLE_AGENCE:administrateur',
                'ROLE_AGENCE:modificateur',
                'ROLE_AGENCE:lecteur'
              ]}>
              <Secteur />
            </RequireAuth>} />


          <Route path="/integration"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_AGENCE:superadministrateur',
                'ROLE_AGENCE:administrateur',
                'ROLE_AGENCE:modificateur',
                'ROLE_AGENCE:lecteur'
              ]}>
              <Integration />
            </RequireAuth>} />

          <Route path="/partenaire"
            element={<RequireAuth
              ArrayRoles={[
                'ROLE_PARTENAIRE:superadministrateur',
                'ROLE_PARTENAIRE:administrateur',
                'ROLE_PARTENAIRE:modificateur',
                'ROLE_PARTENAIRE:lecteur'
              ]}>
              <Partenaire />
            </RequireAuth>} />

        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
