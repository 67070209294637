import React from 'react';
import Navbar from '../../components/navbar';
import ReactDOM from "react-dom";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import Profilebar from "../../components/profilebar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons'


class DetailAgences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            tokenJWT: this.props.accessToken,
            dataUser: this.props.dataUser,
            deleteFonction: null,
            displayFormAgence: false,
            //this.state.params.idAgence          
        }
        this.addCodePostalActiviteAgence = this.addCodePostalActiviteAgence.bind(this)
        this.seachSecteur = this.seachSecteur.bind(this)
        this.editAgence = this.editAgence.bind(this)
        this.deleteAgence = this.deleteAgence.bind(this)
    }

    async componentDidMount() {
        await this.getData()
        await this.getSecteur()

        this.displaySecteur(this.state.dataSecteur)
    }

    async getData() {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_AgenceAPI + '/api/agences/infoSumup/' + this.state.params.idAgence,
            headers: {
                'Authorization': 'Bearer ' + this.state.tokenJWT
            }
        };

        var response = await axios(config)
        this.setState({
            dataAgences: response.data
        });
    }

    async getSecteur() {
        var config = {
            method: 'get',
            url: process.env.REACT_APP_AgenceAPI + '/api/secteurs/agence/' + this.state.params.idAgence,
            headers: {
                'Authorization': 'Bearer ' + this.state.tokenJWT
            }
        };

        var response = await axios(config)
        this.setState({
            dataSecteur: response.data
        });
    }

    addCodePostalActiviteAgence(dataForm) {
        dataForm.preventDefault();

        var codePostal = dataForm.target.codePostal.value
        var idAgence = this.state.params.idAgence
        var valeursSelectionnees = [];

        dataForm.target.activites.forEach(activite => {
            if (activite.checked) {
                valeursSelectionnees.push(activite.value);
            }
        });

        //console.log(valeursSelectionnees, codePostal, idAgence)
        if (valeursSelectionnees.length == 0) {
            const activitesInput = document.querySelector('input[name="activites"]');
            activitesInput.setCustomValidity('Vous devez choisir au moins une activité');
            activitesInput.reportValidity();
        } else {
            var config = {
                method: 'post',
                url: process.env.REACT_APP_AgenceAPI + '/api/secteurs/newsecteur/' + idAgence,
                headers: {
                    'Authorization': 'Bearer ' + this.state.tokenJWT
                },
                data: {
                    activites: valeursSelectionnees,
                    codePostal: codePostal
                }
            };
            axios(config)
        }
    }

    displayActivite(activite) {
        switch (activite.toLowerCase()) {
            case "chaleur" || "pac":
                return <div className='w-2/3 bg-green-300 px-2 py-1.5 rounded-r'>
                    Pompe à chaleur
                </div>
            case "clim" || "climatiseur":
                return <div className='w-2/3 bg-blue-300 px-2 py-1.5 rounded-r'>
                    Climatiseur
                </div>
            case "gaz":
                return <div className='w-2/3 bg-orange-300 px-2 py-1.5 rounded-r'>
                    Chaudière à gaz
                </div>
            case "fioul":
                return <div className='w-2/3 bg-red-300 px-2 py-1.5 rounded-r'>
                    Chaudière à fioul
                </div>
        }
    }

    displaySecteur(dataSecteur) {
        if (dataSecteur != 'undefined' && dataSecteur != null)
            ReactDOM.render(dataSecteur.map((data) => {
                return <div className='flex h-min'>
                    <div className='w-1/3 bg-white px-2 py-1.5 rounded-l text-center flex'>
                        <span className='my-auto'>{data.codepostal}</span>
                    </div>
                    {this.displayActivite(data.activite.nom)}
                </div>
            }), document.getElementById("secteur"));

    }

    seachSecteur(event) {
        this.displaySecteur(this.state.dataSecteur.filter(objet => objet.codepostal.toString().includes(event.target.value)))
    }

    editAgence() {
        this.setState({
            displayFormAgence: true
        })
    }

    saveEditAgence() {
        const form = document.getElementById('coordonnes');
        const formData = new FormData(form);
        const idAgence = this.state.params.idAgence
        const dataAgence = {};
        var next = true;

        for (const [name, value] of formData.entries()) {
            if (value != null && value != "" && value != " ") {
                dataAgence[name] = value;
            } else {
                next = false
                const activitesInput = document.querySelector('input[name="' + name + '"]');
                activitesInput.setCustomValidity('Veuillez renseigner cette information');
                activitesInput.reportValidity();
            }
        }

        if (next) {
            var config = {
                method: 'put',
                url: process.env.REACT_APP_AgenceAPI + '/api/agences/' + idAgence,
                headers: {
                    'Authorization': 'Bearer ' + this.state.tokenJWT
                },
                data: dataAgence
            };
            axios(config)
                .then(response => {
                    console.log(response.data)
                    this.setState({
                        displayFormAgence: false,
                        dataAgences: response.data
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    openPopUp() {
        document.getElementById('inputVerification').value = null;
        document.getElementById('popup').classList.remove('hidden')
        document.body.classList.add('overflow-hidden')
        document.getElementById('messageErrorInput').classList.add('hidden')
    }

    closePopUp() {
        document.getElementById('popup').classList.add('hidden')
        document.body.classList.remove('overflow-hidden')
        document.getElementById('messageErrorInput').classList.add('hidden')
        document.getElementById('inputVerification').value = null;
    }

    async deleteAgence() {
        let valueVerification = document.getElementById('inputVerification').value;
        const idAgence = this.state.params.idAgence

        if (valueVerification === "SUPPRIMER") {
            var tokenJWT = this.props.accessToken

            var config = {
                method: 'delete',
                url: process.env.REACT_APP_AgenceAPI + '/api/agences/' + idAgence,
                headers: {
                    'Authorization': 'Bearer ' + tokenJWT,
                    'Content-Type': 'application/json'
                }
            };
            document.getElementById('popup').classList.add('hidden')
            document.getElementById('spinner').classList.remove('hidden')
            document.body.classList.add('overflow-hidden')

            await axios(config)
            await document.body.classList.remove('overflow-hidden')

            this.props.navigation('/tableau-des-agences')
        } else {
            document.getElementById('messageErrorInput').classList.remove('hidden')
        }
    }

    render() {

        return (
            <>
                <Navbar where={"agences"} dataUser={this.state.dataUser} />
                <div className="flex flex-col w-full">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className="flex flex-col  bg-gray-200 h-full">

                        <div class="transition-colors duration-300 flex justify-between bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Agence:  {this.state.dataAgences ? this.state.dataAgences.libelle : null} </h3>
                            <div className='ml-auto my-auto flex space-x-3'>
                                {this.state.dataUser.roles.some(role => ['ROLE_AGENCE:superadministrateur', 'ROLE_AGENCE:administrateur', 'ROLE_AGENCE:modificateur'].includes(role))
                                    ? this.state.displayFormAgence ?
                                        <div onClick={() => this.saveEditAgence()} className="flex group mt-1 my-auto text-white right-2.5 bottom-2.5 bg-blue-500 hover:bg-blue-600 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded">
                                            <svg class="w-5 h-5 flex items-center cursor-pointer text-white mx-auto mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                <FontAwesomeIcon icon={faSave} />
                                            </svg>
                                            Enregistrer</div>
                                        : <div onClick={() => this.editAgence()} className="flex group mt-1 my-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded">
                                            <svg class="w-5 h-5 flex items-center cursor-pointer text-white mx-auto mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </svg>
                                            Modifier</div>

                                    : null}
                                {this.state.dataUser.roles.some(role => ['ROLE_AGENCE:superadministrateur', 'ROLE_AGENCE:administrateur'].includes(role))
                                    ? <div onClick={() => this.openPopUp()} className="flex group  mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-red-500 hover:bg-red-600 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 flex items-center cursor-pointer text-white ml-auto mr-1" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                        </svg>
                                        Supprimer</div> : null}
                            </div>
                        </div>


                        <div className='px-10 py-[1vw] flex flex-col h-full'>
                            {this.state.dataAgences
                                ? <div className='grid grid-cols-3 ecran2:gap-8 gap-4'>
                                    <div className='flex flex-col bg-white rounded ecran2:px-6 px-4 py-3 border border-gray-300'>
                                        <h2 className='text-lg font-medium mb-2'> Coordonnées</h2>
                                        {this.state.displayFormAgence ? (
                                            <form class="space-y-1" id="coordonnes" >
                                                <p className='flex'>
                                                    <span className='w-3/12'> Adresse:</span>
                                                    <div className='flex space-x-0.5 w-9/12 text-sm'>
                                                        <input type={"text"} className="bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 w-1/12" defaultValue={this.state.dataAgences.adresse.numero} name="numero" onClick={(element) => { element.target.select() }} required />
                                                        <input type={"text"} className="bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 w-2/12" defaultValue={this.state.dataAgences.adresse.quaru} name="quaru" onClick={(element) => { element.target.select() }} required />
                                                        <input type={"text"} className="bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 w-4/12" defaultValue={this.state.dataAgences.adresse.rue} name="rue" onClick={(element) => { element.target.select() }} required />
                                                        <input type={"text"} className="bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 w-2/12" defaultValue={this.state.dataAgences.adresse.codepostal} name="codepostal" onClick={(element) => { element.target.select() }} required />
                                                        <input type={"text"} className="bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 w-2/12" defaultValue={this.state.dataAgences.adresse.ville} name="ville" onClick={(element) => { element.target.select() }} required />
                                                        <input type={"text"} className="bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 w-2/12" defaultValue={this.state.dataAgences.adresse.pays} name="pays" onClick={(element) => { element.target.select() }} required />
                                                    </div>
                                                </p>
                                                <p className='flex'><span className='w-3/12'> Email:</span> <input type={"text"} className="text-sm bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 w-3/4" defaultValue={this.state.dataAgences.email} name="email" /> </p>
                                                <p className='flex'><span className='w-3/12'> Contact:</span> <input type={"text"} className="text-sm bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 w-3/4" defaultValue={this.state.dataAgences.distributionList} name="distributionList" /> </p>
                                                <p className='flex'><span className='w-3/12'> Téléphone:</span> <input type={"text"} className="text-sm bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 " defaultValue={this.state.dataAgences.telephone} name="telephone" />  </p>
                                            </form>
                                        ) : (
                                            <div className='space-y-1' id='coordonnes'>
                                                <p>Adresse: {this.state.dataAgences.adresse.numero} {this.state.dataAgences.adresse.quaru} {this.state.dataAgences.adresse.rue} {this.state.dataAgences.adresse.codepostal} {this.state.dataAgences.adresse.ville} {this.state.dataAgences.adresse.pays}</p>
                                                <p>Email: {this.state.dataAgences.email}</p>
                                                <p>Contact: {this.state.dataAgences.distributionList}</p>
                                                <p>Téléphone: {this.state.dataAgences.telephone}  </p>
                                            </div>
                                        )}

                                    </div>
                                    <div className='flex flex-col bg-white rounded ecran2:px-6 px-4 py-3 border border-gray-300'>
                                        <h2 className='text-lg font-medium mb-2'>Info Oxygène</h2>
                                        <div className='space-y-1'>
                                            <p>Type: {this.state.dataAgences.type ? this.state.dataAgences.type.nom : null}</p>
                                            <p>Dossier: {this.state.dataAgences.dossier}</p>
                                            <p>Nom: {this.state.dataAgences.nom}</p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col bg-white rounded ecran2:px-6 px-4 py-3 border border-gray-300'>
                                        <h2 className='text-lg font-medium mb-2'> Info Sumup </h2>
                                        <div className=' space-y-1'>
                                            <p>Email sumup: {this.state.dataAgences.sumup ? this.state.dataAgences.sumup.pay_email : null}</p>
                                            <p>Email reception: {this.state.dataAgences.sumup ? this.state.dataAgences.sumup.send_email : null}</p>
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {this.state.dataSecteur
                                ? <div className='flex flex-col text-sm ecran2:mt-20 mt-6 ecran2:h-[26vw] h-[24vw]'>
                                    <div className=' grid ecran2:grid-cols-8 grid-cols-6 gap-4 mb-4 pr-6'>
                                        <div className='relative col-span-2'>
                                            <input type="text" class=" bg-white border rounded px-6 py-2 border border-gray-300  w-full " onChange={(event) => this.seachSecteur(event)} id="codePostal" name="codePostal" pattern="[0-9]{5}" max="99999" placeholder="54710"></input>
                                            <div class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" class="svg-inline--fa fa-magnifying-glass text-gray-400 text-[0.7vw] mr-1 my-auto " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"></path></svg></div>
                                        </div>
                                    </div>
                                    <div id='secteur' className=' max-h-[24vw] overflow-auto grid ecran2:grid-cols-8 grid-cols-6 gap-4 pr-2'> </div>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
                <div id='spinner' class="hidden min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="flex justify-center items-center h-full">
                        <img class="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
                    </div>
                </div>
                <div id="popup" class="min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div onClick={this.closePopUp} class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="w-full flex flex-col max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                        <div class="text-center p-5 flex-auto justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-16 h-16 flex items-center text-red-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                            <h2 class="text-xl font-bold py-4 ">Etes-vous sûrs ?</h2>
                            <p class="text-sm text-gray-500 px-8">Si vous souhaitez réellement supprimer écrivez SUPPRIMER. Ce processus est irréversible.</p>
                        </div>
                        <samp id='messageErrorInput' class='mx-auto text-red-500 hidden'>Saisie incorrect </samp>
                        <input id='inputVerification' type='text' class="border border-gray-300 p-2 my-2 rounded-md focus:outline-none focus:ring-2 ring-blue-200 mx-auto" />
                        <div class="p-3  mt-2 text-center space-x-4 md:block">
                            <button onClick={this.closePopUp} class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                Annuler
                            </button>
                            <button onClick={this.deleteAgence} class="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-red-600">
                                Suprimer
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

};

export default (props) => (
    <DetailAgences
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);