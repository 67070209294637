import React from "react";
import Navbar from '../../components/navbar';
import Profilebar from "../../components/profilebar"
import { useParams, useNavigate, Link } from "react-router-dom";
import { faEdit, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotifBar from '../../components/notifBar'
import axios from 'axios';

class Partenaire extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            dataUser: this.props.dataUser,
            listProcess: ["Informatif", "etape_materiel_pret", "etape_defaut", "etape_cloture", "etape_resolu"]

        }
        this.formAddAccountPartenaire = this.formAddAccountPartenaire.bind(this)
        this.formDelete = this.formDelete.bind(this)
        this.formUpdate = this.formUpdate.bind(this)
        this.generatePassword = this.generatePassword.bind(this)
        this.getinputMDP = this.getinputMDP.bind(this)
        this.getPartenaire = this.getPartenaire.bind(this)
        this.getAccountPartenaire = this.getAccountPartenaire.bind(this)


    }
    async componentDidMount() {
        await this.getPartenaire()
        this.getAccountPartenaire()

    }

    async getPartenaire() {
        var AgenceAPI = process.env.REACT_APP_AgenceAPI
        var tokenJWT = this.props.accessToken

        var config = {
            method: 'get',
            url: AgenceAPI + '/api/partenaires',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        var response = await axios(config)

        this.setState({
            partenaires: response.data
        })
    }

    async getAccountPartenaire() {
        var ExternalUser = process.env.REACT_APP_ExternalUser
        var tokenJWT = this.props.accessToken

        var config = {
            method: 'get',
            url: ExternalUser + '/api/partenaires',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        var response = await axios(config)


        response.data.map(d => {
            d.partenaire = this.state.partenaires.find(p => p.id == d.idPartenaire)
        })

        this.setState({
            accountPartenaires: response.data
        })
    }


    async formAddAccountPartenaire(event) {
        event.preventDefault();
        var ExternalUser = process.env.REACT_APP_ExternalUser
        var tokenJWT = this.props.accessToken

        if (!this.state.partenaires.find(t => t.username == event.target.mailAdd.value)) {
            var config = {
                method: 'post',
                url: ExternalUser + '/api/partenaires',
                headers: {
                    'Authorization': 'Bearer ' + tokenJWT
                },
                data: {
                    username: event.target.mailAdd.value,
                    password: event.target.paswwordFormAdd.value,
                    role: event.target.partenaireRolesFormAdd.value,
                    idPartenaire: event.target.idPartenaireFormAdd.value
                }
            };

            var response = await axios(config)
                .then((response => {
                    return response
                }))
                .catch(error => {
                    return error.response
                })


            if (response.status == 200) {
                let newAccountPartenaires = response.data
                let partenaires = this.state.partenaires
                let accountPartenaires = this.state.accountPartenaires

                newAccountPartenaires.password = event.target.paswwordFormAdd.value
                newAccountPartenaires.partenaire = partenaires.find(p => p.id == newAccountPartenaires.idPartenaire)

                accountPartenaires.unshift(newAccountPartenaires)

                this.setState({
                    accountPartenaires: accountPartenaires,
                    configNotifBar: {
                        color: "green",
                        message: "Le compt partenaire a été ajouté"
                    },
                })

                setTimeout(() => {
                    this.setState({
                        configNotifBar: null
                    })
                }, 3000)

                document.getElementById("formAdd").reset();
                this.getinputMDP('paswwordFormAdd')
            } else {
                document.getElementById('mailAdd').setCustomValidity("Veuillez entrer une adresse e-mail non utilisé")
                document.getElementById('mailAdd').reportValidity()
            }
        } else {
            document.getElementById('mailAdd').setCustomValidity("Veuillez entrer une adresse e-mail non utilisé")
            document.getElementById('mailAdd').reportValidity()
        }


    }

    async formUpdate(event) {
        event.preventDefault();
        var ExternalUser = process.env.REACT_APP_ExternalUser
        var tokenJWT = this.props.accessToken

        var password = event.target.paswwordFormUpdate.value
        var id = event.target.idFromUpdate.value
        var idPartenaire = event.target.idPartenaireFormUpdate.value
        var username = event.target.mailFormUpdate.value

        if (!this.state.partenaires.find(t => t.username == username)) {
            var config = {
                method: 'put',
                url: ExternalUser + '/api/partenaires/' + id,
                headers: {
                    'Authorization': 'Bearer ' + tokenJWT
                },
                data: {
                    username: username,
                    password: password,
                    role: event.target.roleFormUpdate.value,
                    idPartenaire: idPartenaire
                }
            };

            var response = await axios(config)
                .then((response => {
                    return response
                }))
                .catch(error => {
                    return error.response
                })


            if (response.status == 200 || response.status == 204) {

                let partenaires = this.state.partenaires
                let accountPartenaires = this.state.accountPartenaires
                let updateAccountPartenaires = accountPartenaires.find(p => p.id == id)

                updateAccountPartenaires.username = username
                updateAccountPartenaires.partenaire = partenaires.find(p => p.id == idPartenaire)

                if (password && typeof password === "string" && password !== '') {
                    updateAccountPartenaires.password = password
                }

                this.setState({
                    accountPartenaires: accountPartenaires,
                    configNotifBar: {
                        color: "green",
                        message: "Le compt partenaire a été modifié"
                    },
                    popUp: null
                })

                setTimeout(() => {
                    this.setState({
                        configNotifBar: null
                    })
                }, 3000)

            } else {
                document.getElementById('mailFormUpdate').setCustomValidity("Veuillez entrer une adresse e-mail non utilisé")
                document.getElementById('mailFormUpdate').reportValidity()
            }
        } else {
            document.getElementById('mailFormUpdate').setCustomValidity("Veuillez entrer une adresse e-mail non utilisé")
            document.getElementById('mailFormUpdate').reportValidity()
        }
    }


    async formDelete(event) {
        event.preventDefault();
        var ExternalUser = process.env.REACT_APP_ExternalUser
        var tokenJWT = this.props.accessToken

        var config = {
            method: 'delete',
            url: ExternalUser + '/api/partenaires/' + event.target.idFromDelete.value,
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };


        var response = await axios(config)
            .then((response => {
                return response
            }))
            .catch(error => {
                return error.response
            })

        if (response.status == 200 || response.status == 204) {

            let newAccountPartenaires = await this.state.accountPartenaires.filter(p => p.id != event.target.idFromDelete.value)
            this.setState({
                configNotifBar: {
                    color: "green",
                    message: "Le compt partenaire a été supprimé"
                },
                accountPartenaires: newAccountPartenaires,
                popUp: null
            })

            setTimeout(() => {
                this.setState({
                    configNotifBar: null
                })
            }, 3000)

        } else {
            this.setState({
                configNotifBar: {
                    color: "red",
                    message: "Aucun compt partenaire n'a été supprimé"
                },
                popUp: null
            })

            setTimeout(() => {
                this.setState({
                    configNotifBar: null
                })
            }, 3000)
        }
    }

    popUpDelete(display, partenaire = null) {
        if (display == 'on') {
            this.setState({
                popUp: <div id="popUpDelete" class="min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover "  >
                    <div onClick={() => this.popUpDelete('off')} class="absolute bg-gray-500 bg-opacity-75 transition-opacity opacity-100 inset-0 z-0"></div>
                    <form class="w-full flex flex-col max-w-lg relative mx-auto my-auto rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg bg-white opacity-100 translate-y-0 sm:scale-100"
                        onSubmit={this.formDelete}>
                        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left px-4 pb-4 pt-5 sm:p-6 sm:pb-4" >
                            <h3 class=" font-medium" id="headlessui-dialog-title-:r3:" data-headlessui-state="open">Suppresion du compt </h3>
                            <div class="mt-2 space-y-1" >
                                <p class=" text-sm ">Sélectionner le status qui remplacera le status supprimer ?</p>
                                <input type="hidden" id="idFromDelete" value={partenaire.id} />
                                <input type="hidden" id="usernameFromDelete" value={partenaire.username} />
                            </div>
                        </div>

                        <div class="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 font-medium ">
                            <button type="submit" class="justify-center bg-green-500 px-3 py-2 text-sm shadow-sm hover:bg-green-600 text-white sm:ml-3 sm:w-auto">Confirmer</button>
                            <button type="button" class=" justify-center bg-red-500 px-3 py-2 text-sm text-white shadow-sm hover:bg-red-500 sm:mt-0 sm:w-auto" onClick={() => this.popUpDelete('off')}>Annuler</button>
                        </div>
                    </form>
                </div>
            })

        } else {
            this.setState({
                popUp: null
            })
        }
    }

    popUpUpdate(display, partenaire = null) {

        if (display == 'on') {

            this.setState({
                popUp: <div id="popUpUpdate" class="min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover "  >
                    <div onClick={() => this.popUpUpdate('off')} class="absolute bg-gray-500 bg-opacity-75 transition-opacity opacity-100 inset-0 z-0"></div>
                    <form class="w-full flex flex-col max-w-lg relative mx-auto my-auto rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg bg-white opacity-100 translate-y-0 sm:scale-100"
                        onSubmit={this.formUpdate}>
                        <div class="px-4 pb-4 pt-5 sm:p-6 sm:pb-4 mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 class=" font-medium" id="headlessui-dialog-title-:r3:" data-headlessui-state="open">Modification d'un compte utilisateur</h3>
                            <div class="mt-2 space-y-1 " >
                                <input type="hidden" id="idFromUpdate" value={partenaire.id} />
                                <div className="flex flex-col space-y-2">
                                    <label for="mailFormUpdate" className="text-left block font-medium leading-6 text-gray-900 ">
                                        Adresse mail
                                    </label>
                                    <input required pattern="[^\s@]+@[^\s@]+\.[^\s@]+" name="mailFormUpdate" id='mailFormUpdate' max={1000} defaultValue={partenaire.username} className="py-1.5 px-2 h-min bg-transparent border border-gray-500 rounded-none focus:outline-none focus:border-blue-600 focus:ring-blue-600 focus:ring-1 "></input>
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <label for="paswwordFormUpdate" className="text-left block font-medium leading-6 text-gray-900 flex">
                                        Mot de passe
                                        <svg class="w-4 h-4 flex items-center cursor-pointer text-gray-500 ml-auto my-auto" viewBox="0 0 20 20" fill="currentColor" onClick={() => { document.getElementById('paswwordFormUpdate').classList.remove('hidden'); document.getElementById('button_new_password_form_update').classList.add('hidden'); this.getinputMDP('paswwordFormUpdate') }}>
                                            <FontAwesomeIcon icon={faArrowsRotate} />
                                        </svg>
                                    </label>
                                    <button type="button" id="button_new_password_form_update" onClick={(event) => { document.getElementById('paswwordFormUpdate').classList.remove('hidden'); event.target.classList.add('hidden'); this.getinputMDP('paswwordFormUpdate') }} class="justify-center bg-blue-500 px-3 py-2 text-sm text-white shadow-sm hover:bg-blue-600 sm:w-auto">Générer nouveau mot de passe</button>
                                    <input required readOnly name="paswwordFormUpdate" id='paswwordFormUpdate' defaultValue={null} max={1000} className={"hidden py-1.5 px-2 h-min bg-transparent border border-gray-500 rounded-none focus:outline-none focus:border-blue-600 focus:ring-blue-600 focus:ring-1 "} ></input>
                                </div>
                                <div className="flex flex-col space-y-2 ">
                                    <label for="idPartenaireFormUpdate" className="text-left font-medium leading-6 text-gray-900 ">
                                        Partenaire
                                    </label>
                                    <input type="hidden" id="roleFormUpdate" name="roles" defaultValue={partenaire.partenaire.roleAssocier} ></input>
                                    <select name="idPartenaireFormUpdate" id="idPartenaireFormUpdate" onChange={(event) => this.changeRole(event, "roleFormUpdate")} className="w-full py-1.5 bg-transparent border border-gray-500 rounded-none focus:outline-none focus:border-blue-600 focus:ring-blue-600 focus:ring-1" >
                                        {
                                            this.state.partenaires.map((p) => {
                                                return <option className="bg-white " value={p.id} selected={partenaire.idPartenaire == p.id ? true : false}> {p.nom} </option>
                                            })
                                        }

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 font-medium ">
                            <button type="submit" class="justify-center bg-green-500 px-3 py-2 text-sm text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto">Confirmer</button>
                            <button type="button" class=" justify-center bg-red-500 px-3 py-2 text-sm text-white shadow-sm hover:bg-red-500 sm:mt-0 sm:w-auto" onClick={() => this.popUpUpdate('off')}>Annuler</button>
                        </div>
                    </form>
                </div>
            })
        } else {
            this.setState({
                popUp: null
            })
        }
    }

    changeRole(event, id) {

        var partenaire = this.state.partenaires.filter((p) => p.id == event.target.value)
        document.getElementById(id).value = partenaire[0].roleAssocier

    }

    generatePassword() {
        const length = 11
        const lowercase = "abcdefghijklmnopqrstuvwxyz";
        const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const digits = "0123456789";
        const specialChars = "$&+,:;=?@#|'<>.^*()%!-";

        const allChars = lowercase + uppercase + digits + specialChars;
        let password = "";

        // Ajouter un caractère aléatoire de chaque catégorie
        password += lowercase[Math.floor(Math.random() * lowercase.length)];
        password += uppercase[Math.floor(Math.random() * uppercase.length)];
        password += digits[Math.floor(Math.random() * digits.length)];
        password += specialChars[Math.floor(Math.random() * specialChars.length)];

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * allChars.length);
            password += allChars[randomIndex];
        }

        return password;
    }

    getinputMDP(id) {
        var mdp = this.generatePassword()
        document.getElementById(id).value = mdp
    }



    render() {

        return (
            <>
                <Navbar where={"partenaire"} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-screen">
                    <Profilebar username={this.state.dataUser.userName} />

                    <div class="  bg-gray-200 h-full flex flex-col ">
                        <div class="transition-colors duration-300 flex justify-between bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Partenaire </h3>
                        </div>

                        <div className="px-10  py-[1vw]">
                            {this.state.configNotifBar
                                ? <div className="w-full relative ">
                                    <div className="w-1/3 mx-auto mb-2 absolute inset-x-0 -mt-4">
                                        <NotifBar message={this.state.configNotifBar.message} color={this.state.configNotifBar.color} />
                                    </div>
                                </div>
                                : null
                            }


                            {['ROLE_PARTENAIRE:superadministrateur', 'ROLE_PARTENAIRE:administrateur', 'ROLE_PARTENAIRE:modificateur'].some(role => this.state.dataUser.roles.includes(role)) ?
                                <form id="formAdd" onSubmit={this.formAddAccountPartenaire} class=" transition-colors space-y-10 bg-white px-10 ecran2:py-10 py-5 shadow-md flex flex-col ecran2:mb-10 mb-4">
                                    <div className="space-x-5 flex ">
                                        <div className="flex flex-col space-y-2 w-1/2">
                                            <label for="mailAdd" className="text-left block font-medium leading-6 text-gray-900 ">
                                                Adresse mail
                                            </label>
                                            <input required pattern="[^\s@]+@[^\s@]+\.[^\s@]+" placeholder="Adresse mail" name="mailAdd" id='mailAdd' max={1000} className="py-1.5 px-2 h-min bg-transparent border border-gray-500 rounded-none focus:outline-none focus:border-blue-600 focus:ring-blue-600 focus:ring-1 "></input>
                                        </div>
                                        <div className="flex flex-col space-y-2 w-1/4">
                                            <label className="flex text-left font-medium leading-6 text-gray-900 ">
                                                Mot de passe
                                                <svg class="w-4 h-4 flex items-center cursor-pointer text-gray-500 ml-auto my-auto" viewBox="0 0 20 20" fill="currentColor" onClick={() => this.getinputMDP('paswwordFormAdd')}>
                                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                                </svg>
                                            </label>
                                            <input required name="paswwordFormAdd" id='paswwordFormAdd' max={1000} defaultValue={this.generatePassword()} className="py-1.5 px-2 h-min bg-transparent border border-gray-500 rounded-none focus:outline-none focus:border-blue-600 focus:ring-blue-600 focus:ring-1 " readOnly ></input>
                                        </div>
                                        <div className="flex flex-col space-y-2 w-1/4">
                                            <label for="color" className="text-left font-medium leading-6 text-gray-900 ">
                                                Partenaire
                                            </label>
                                            <input type="hidden" id="partenaireRolesFormAdd" name="roles" defaultValue={this.state.partenaire ? this.state.partenaires[0].roleAssocier : null} ></input>
                                            <select required id="selectColor" name="idPartenaireFormAdd" className="w-full py-1.5 bg-transparent border border-gray-500 h-[38px]" onChange={(event) => this.changeRole(event, "partenaireRolesFormAdd")}>
                                                <option className="bg-white " value="" >Sélectionnez un partenaire</option>
                                                {
                                                    this.state.partenaires ? this.state.partenaires.map((partenaire) => {
                                                        return <option className="bg-white " value={partenaire.id}>{partenaire.nom}</option>
                                                    })
                                                        : null
                                                }

                                            </select>
                                        </div>
                                        <button type="submit" class="w-[9rem] bg-blue-500 px-3 py-2 text-sm hover:bg-blue-600 ml-auto h-fit mt-auto text-white "  >Ajouter</button>
                                    </div>
                                </form>
                                : null}

                            <table className="text-left text-gray-500  shadow w-full  flex flex-col  h-full overflow-auto ecran2:max-h-[60vh] max-h-[59vh]">
                                <thead className="text-gray-700 bg-gray-50 z-10 sticky top-0  ">
                                    <tr className="flex w-full">
                                        <th scope="col" className="px-6 py-3 font-medium w-4/12">
                                            Adresse mail
                                        </th>
                                        <th scope="col" className="px-6 py-3 font-medium w-3/12">
                                            Partenaire
                                        </th>
                                        <th scope="col" className="px-6 py-3 font-medium w-3/12">
                                            Mot de passe
                                        </th>
                                        {['ROLE_PARTENAIRE:superadministrateur', 'ROLE_PARTENAIRE:administrateur', 'ROLE_PARTENAIRE:modificateur'].some(role => this.state.dataUser.roles.includes(role)) ?
                                            <th scope="col" className="px-6 py-3 font-medium w-2/12"> </th>
                                            : null}
                                    </tr>
                                </thead>
                                <tbody className="flex w-full flex-col">
                                    {
                                        this.state.accountPartenaires ?
                                            this.state.accountPartenaires.sort((a, b) => {
                                                // Comparer les noms
                                                if (a.partenaire.nom < b.partenaire.nom) {
                                                    return -1;
                                                }
                                                if (a.partenaire.nom > b.partenaire.nom) {
                                                    return 1;
                                                }
                                                // Si les noms sont égaux, comparer les usernames
                                                if (a.username < b.username) {
                                                    return -1;
                                                }
                                                if (a.username > b.username) {
                                                    return 1;
                                                }
                                                return 0;
                                            }).map((account) =>
                                                <tr scope="row" class=" w-full bg-white  hover:bg-gray-200  transition-all duration-300 flex flex-row" >
                                                    <td class={`px-6 py-4  flex  w-4/12`}> <span className=" my-auto">{account.username}</span>  </td>
                                                    <td class={`px-6 py-4  flex  w-3/12 `}> <span className=" my-auto">{account.partenaire.nom}</span>  </td>
                                                    <td class={`px-6 py-4  flex  w-3/12`}> <span className=" my-auto">********</span>  </td>
                                                    {['ROLE_PARTENAIRE:superadministrateur', 'ROLE_PARTENAIRE:administrateur', 'ROLE_PARTENAIRE:modificateur'].some(role => this.state.dataUser.roles.includes(role)) ?
                                                        <td class="px-6 py-4 space-x-2 flex w-fit">
                                                            <div className="flex my-auto space-x-4">
                                                                <button class="w-[6rem] flex-grow bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-3 text-sm" onClick={() => this.popUpUpdate("on", account)} >Modifier</button>
                                                                {['ROLE_PARTENAIRE:superadministrateur', 'ROLE_PARTENAIRE:administrateur'].some(role => this.state.dataUser.roles.includes(role)) ?
                                                                    <button class="w-[6rem] flex-grow bg-red-500 hover:bg-red-600 text-white py-2 px-3 text-sm" onClick={() => this.popUpDelete("on", account)} >Supprimer</button>
                                                                    : null}
                                                            </div>
                                                        </td>
                                                        : null}
                                                </tr>
                                            )
                                            : null
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* Pop Up pour la suppresion et modifcation d'un partneaire */}
                {this.state.popUp}


            </>

        )
    }
}


export default (props) => (
    <Partenaire
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);

