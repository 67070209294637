import React from 'react';
import Navbar from '../../components/navbar';
import Profilebar from "../../components/profilebar"
import { useParams, useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInfo } from '@fortawesome/free-solid-svg-icons'
import ImportExportSecteur from '../../components/agence/importExportSecteur'
import axios from 'axios';


class Secteur extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            dataUser: this.props.dataUser,
        }
        this.getData = this.getData.bind(this);
        this.filtreData = this.filtreData.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        const AgenceAPI = process.env.REACT_APP_AgenceAPI
        const tokenJWT = this.props.accessToken

        var secteurs = await axios({
            method: 'get',
            url: AgenceAPI + '/api/secteurs/exports/',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        }).then(response => {
            return response.data
        })

        var chaleur = secteurs.filter(secteur => secteur.activite.nom === "CHALEUR");
        var clim = secteurs.filter(secteur => secteur.activite.nom === "CLIM");
        var fioul = secteurs.filter(secteur => secteur.activite.nom === "FIOUL");
        var gaz = secteurs.filter(secteur => secteur.activite.nom === "GAZ");

        var agences = await axios({
            method: 'get',
            url: AgenceAPI + '/api/agences/l',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        }).then(response => {
            return response.data
        })

        this.setState({
            secteurs: secteurs,
            agences: agences,
            chaleur: chaleur,
            clim: clim,
            fioul: fioul,
            gaz: gaz
        })

    }

    filtreData(event) {
        console.log(event.target.value)
        if (event.target.value != "") {
            var secteurs = this.state.secteurs.filter(secteur => secteur.agence.libelle == event.target.value)
        } else {
            var secteurs = this.state.secteurs
        }


        var chaleur = secteurs.filter(secteur => secteur.activite.nom === "CHALEUR");
        var clim = secteurs.filter(secteur => secteur.activite.nom === "CLIM");
        var fioul = secteurs.filter(secteur => secteur.activite.nom === "FIOUL");
        var gaz = secteurs.filter(secteur => secteur.activite.nom === "GAZ");

        this.setState({
            chaleur: chaleur,
            clim: clim,
            fioul: fioul,
            gaz: gaz
        })

    }


    render() {
        return (
            <>
                <Navbar where={"secteur"} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-screen">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className=" bg-gray-200 h-full flex flex-col">
                        <div class="transition-colors duration-300 flex justify-between bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Secteur</h3>
                            <div className='flex my-auto space-x-4'>
                                <ImportExportSecteur accessToken={this.props.accessToken} dataUser={this.props.dataUser} />
                            </div>
                        </div>
                        <div className='px-10 py-[1vw]'>
                            <div className='grid grid-cols-4 gap-6 mb-3 '>
                                <div className='relative col-span-1 hidden'>
                                    <input type="text" class=" bg-white border rounded px-6 py-2 border border-gray-300  w-full " onChange={this.filtreData} id="codePostal" name="codePostal" pattern="[0-9]{5}" max="99999" placeholder="54710"></input>
                                    <div class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" class="svg-inline--fa fa-magnifying-glass text-gray-400 text-[0.7vw] mr-1 my-auto " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"></path></svg></div>
                                </div>
                                <div>
                                    <select required="" id="agence" name="agence" onChange={this.filtreData} class="block w-full border border-gray-300 rounded h-full  px-6 py-2 ">
                                        <option value=""> Toutes les agences</option>
                                        {
                                            this.state.agences
                                                ? this.state.agences.map(agence => {
                                                    return <option value={agence.libelle}> {agence.libelle}</option>
                                                })
                                                : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='grid grid-cols-4 gap-6 '>
                                <div className='bg-white px-4 py-5 rounded-sm flex flex-col '>
                                    <span className='text-lg font-medium mb-2'>Pompe à chaleur</span>
                                    <div className='ecran2:max-h-[32vw] max-h-[27vw] flex flex-col overflow-auto'>
                                        {
                                            this.state.chaleur
                                                ? this.state.chaleur.map(secteur => {
                                                    return <div className='flex text-overflow white-space overflow w-full'>
                                                        <span className='px-2 w-1/5'>{secteur.codepostal}</span>
                                                    </div>
                                                })
                                                : null
                                        }
                                    </div>

                                </div>
                                <div className='bg-white px-4 py-5 rounded-sm flex flex-col '>
                                    <span className='text-lg font-medium mb-2'>Climatiseur</span>
                                    <div className='ecran2:max-h-[32vw] max-h-[27vw] flex flex-col overflow-auto'>
                                        {
                                            this.state.clim
                                                ? this.state.clim.map(secteur => {
                                                    return <div className='flex'>
                                                        <span className='px-2 w-1/5'>{secteur.codepostal}</span>
                                                    </div>
                                                })
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className='bg-white px-4 py-5 rounded-sm flex flex-col overflow-auto'>
                                    <span className='text-lg font-medium mb-2'>Chaudière à fioul</span>
                                    <div className='ecran2:max-h-[32vw] max-h-[27vw] flex flex-col overflow-auto'>
                                        {
                                            this.state.fioul
                                                ? this.state.fioul.map(secteur => {
                                                    return <div className='flex'>
                                                        <span className='px-2 w-1/5'>{secteur.codepostal}</span>
                                                    </div>
                                                })
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className='bg-white px-4 py-5 rounded-sm flex flex-col '>
                                    <span className='text-lg font-medium mb-2'>Chaudière à gaz</span>
                                    <div className='ecran2:max-h-[32vw] max-h-[27vw] flex flex-col overflow-auto'>
                                        {
                                            this.state.gaz
                                                ? this.state.gaz.map(secteur => {
                                                    return <div className='flex'>
                                                        <span className='px-2 w-1/5'>{secteur.codepostal}</span>
                                                    </div>
                                                })
                                                : null
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default (props) => (
    <Secteur
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);