import React, { Component } from 'react';
import axios from 'axios';

class linkProductAccessory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTable: null,
            dataTableDisplay: null,
            listeAccesoires: [
                "cumulus",
                "antitartre",
                "regul",
                "desembouage",
                "pot"
            ],
            listeMarque: null,
            filtreAccessory: null,
            filtreMarck: null,
            listSelected: this.props.listeAccesoires
        }
        this.filter = this.filter.bind(this)
        this.removefilter = this.removefilter.bind(this)
        this.selectAccesoiry = this.selectAccesoiry.bind(this)
        this.getDataTable = this.getDataTable.bind(this)
        this.getListeMarque = this.getListeMarque.bind(this)
    }

    componentDidMount() {
        this.getDataTable()
        this.getListeMarque()
        this.verfieChecked()
    }

    componentDidUpdate() {
        this.verfieChecked()
    }

    getDataTable() {
        var env = this
        var EcommAPI = process.env.REACT_APP_EcommAPI
        var tokenJWT = this.props.accessToken

        var config = {
            method: 'get',
            url: EcommAPI + '/api/produits/accessories/all',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT,
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(async function (dataTable) {
                env.setState({
                    dataTable: dataTable.data,
                    dataTableDisplay: dataTable.data
                })
            })
    }

    getListeMarque() {
        var env = this
        var EcommAPI = process.env.REACT_APP_EcommAPI
        var tokenJWT = this.props.accessToken

        var config = {
            method: 'get',
            url: EcommAPI + '/api/marques',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT,
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(async function (dataListeMarque) {
                env.setState({
                    listeMarque: dataListeMarque.data
                })
            })
    }

    async filter(e, typeFilter) {
        var env = this
        var dataTableDisplay
        var filtreAccessory = env.state.filtreAccessory;
        var filtreMarck = env.state.filtreMarck;
        var IdDetail = {
            "cumulus": "id_detail_cumulus",
            "antitartre": "id_detail_antitartre",
            "regul": "id_detail_regul",
            "desembouage": "id_detail_desembouage",
            "pot": "id_detail_pot",
        }

        document.getElementById('checkbox-all').checked = false;

        switch (typeFilter) {
            case "accessory":
                filtreAccessory = e.target.value
                break;
            case "mark":
                filtreMarck = e.target.value
                break;
        }

        dataTableDisplay = await (function () {
            switch (true) {
                case (filtreAccessory != null && filtreMarck != null && filtreAccessory != 'null' && filtreMarck != 'null'):
                    return env.state.dataTable.filter((data) => { return data['id_marque'] == filtreMarck && data[IdDetail[filtreAccessory]] != null })
                case (filtreAccessory != null && filtreAccessory != 'null'):
                    return env.state.dataTable.filter((data) => { return data[IdDetail[filtreAccessory]] != null })
                case (filtreMarck != null && filtreMarck != 'null'):
                    return env.state.dataTable.filter((data) => { return data['id_marque'] == filtreMarck })
                default:
                    return env.state.dataTable
            }
        })()

        this.setState({
            dataTableDisplay: dataTableDisplay,
            filtreAccessory: filtreAccessory,
            filtreMarck: filtreMarck
        })
    }

    removefilter() {
        document.getElementById('filterAccessory').selectedIndex = 0
        document.getElementById('filterMark').selectedIndex = 0
        this.setState({
            dataTableDisplay: this.state.dataTable,
            filtreAccessory: null,
            filtreMarck: null
        })
    }

    selectAccesoiry(e) {
        let listSelected = this.state.listSelected
        let testIsChecked = listSelected.filter((selected) => {
            return selected.id == e.target.value
        })
        if (testIsChecked.length == 0) {
            let accessorySelected = this.state.dataTable.filter((data) => { return data['id'] == e.target.value })
            listSelected.push(accessorySelected[0])
            var newListSelected = listSelected
            this.setState({
                listSelected: newListSelected
            })
        } else {
            var newListSelected = this.state.listSelected.filter((data) => { return data['id'] != e.target.value })
            this.setState({
                listSelected: newListSelected
            })
        }
    }

    async selectAllAccesoiry(e) {

        let listSelected = this.state.listSelected
        let dataTableDisplay = this.state.dataTableDisplay
        let checkboxes = document.getElementsByName('checkbox');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = e.target.checked;
        })
        if (e.target.checked == true) {
            await dataTableDisplay.forEach((data) => {
                let selected = listSelected.filter((selectedccesoiry) => {
                    return selectedccesoiry.id == data.id
                })
                if (selected.length == 0) {
                    listSelected.push(data)
                }
            })
            this.setState({
                listSelected: listSelected
            })
        } else {

            let listSelectedId = this.state.listSelected.map(objet => objet.id)
            let newListSelected = await listSelected.filter((data) => { return !listSelectedId.includes(parseInt(data.id)) })
            this.setState({
                listSelected: newListSelected
            })
        }
    }

    async verfieChecked() {
        let listSelectedId = this.state.listSelected.map(objet => objet.id)
        let checkboxes = document.getElementsByName('checkbox');
        checkboxes.forEach((checkbox) => {
            if (listSelectedId.includes(parseInt(checkbox.value))) {
                checkbox.checked = true;
            } else {
                checkbox.checked = false;
            }

        })
    }

    render() {
        function displayCategory(row) {
            switch (true) {
                case typeof row.id_detail_cumulus != 'undefined' && row.id_detail_cumulus != null:
                    return "cumulus"
                    break;
                case typeof row.id_detail_antitartre != 'undefined' && row.id_detail_antitartre != null:
                    return "antitartre"
                    break;
                case typeof row.id_detail_regul != 'undefined' && row.id_detail_regul != null:
                    return "regul"
                    break;
                case typeof row.id_detail_desembouage != 'undefined' && row.id_detail_desembouage != null:
                    return "desembouage"
                    break;
                case typeof row.id_detail_pot != 'undefined' && row.id_detail_pot != null:
                    return "pot"
                    break;
                default:
                    return 'erreur'
            }
        }

        return (
            <div class="divide-y divide-gray-200">
                <div class="  sm:py-5"><p class="mt-1 max-w-2xl text-sm text-gray-500">Tableau d'association porduit Accesoires</p></div>
                <input name='listAccessoriesId' id='listAccessoriesId' value={JSON.stringify(this.state.listSelected.map(objet => objet.id))} type="hidden"></input>
                {this.state.dataTable != null && this.state.dataTableDisplay != null && this.state.listeMarque != null && this.state.listeAccesoires != null ?
                    <div class="flex flex-col">
                        <div div class="flex mt-2 mb-4 space-x-4">
                            <div class="flex flex-col w-1/4">
                                <label class='mb-1'>Type accessoire</label>
                                <select id="filterAccessory" onChange={(e) => this.filter(e, "accessory")} class="max-w-lg px-2 block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8">
                                    <option value='null' > pas de filtre</option>
                                    {this.state.listeAccesoires.map((Accesoires) =>
                                        <option value={Accesoires} > {Accesoires}</option>
                                    )}
                                </select>
                            </div>
                            <div class="flex flex-col w-1/4">
                                <label class='mb-1'>Marque</label>
                                <select id="filterMark" onChange={(e) => this.filter(e, "mark")} class="max-w-lg px-2 block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8">
                                    <option value='null'> pas de filtre </option>
                                    {this.state.listeMarque.map((option) =>
                                        <option value={option.id} > {option.nom}</option>
                                    )}
                                </select>
                            </div>
                            <div class="flex w-1/4">
                                <div onClick={this.removefilter} type="submit" class="mt-auto bg-blue-400 hover:bg-blue-500 text-white font-medium py-1.5 px-2.5 text-sm rounded cursor-pointer text-center" >
                                    Retirer filtre
                                </div>
                            </div>
                        </div>
                        <div class="rounded-lg bg-white py-3 px-4">
                            <div class=" rounded-lg overflow-hidden" >
                                <div class="overflow-x-auto shadow-md  max-h-[17rem]">
                                    <table class="min-w-full divide-y divide-gray-200 table-fixed min-h-[17rem]">
                                        <thead class="bg-gray-100 ">
                                            <tr class="text-left ">
                                                <th scope="col" class="z-10 bg-gray-100 sticky top-0 p-4 pb-3">
                                                    <div class="flex items-center">
                                                        <input id="checkbox-all" onClick={(e) => { this.selectAllAccesoiry(e) }} type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 " />
                                                    </div>
                                                </th>
                                                <th class="z-10 bg-gray-100 sticky top-0 border-b p-4 pb-3 text-gray-400 tracking-wider uppercase text-xs text-center">Categorie</th>
                                                <th class="z-10 bg-gray-100 sticky top-0 border-b p-4 pb-3 text-gray-400 tracking-wider uppercase text-xs text-center">NOM</th>
                                                <th class="z-10 bg-gray-100 sticky top-0 border-b p-4 pb-3 text-gray-400 tracking-wider uppercase text-xs text-center">MODELE</th>
                                                <th class="z-10 bg-gray-100 sticky top-0 border-b p-4 pb-3 text-gray-400 tracking-wider uppercase text-xs text-center">MARQUE</th>
                                                <th class="z-10 bg-gray-100 sticky top-0 border-b p-4 pb-3 text-gray-400 tracking-wider uppercase text-xs text-center">TARIF (ht)</th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200 ">
                                            {this.state.dataTableDisplay.map((row) =>
                                                <tr class="max-h-[3rem]">
                                                    <td class="p-4 w-4">
                                                        <div class="flex items-center">
                                                            <input value={row.id} onClick={(e) => { this.selectAccesoiry(e) }} type="checkbox" name="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500  " />
                                                        </div>
                                                    </td>
                                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap text-center">{displayCategory(row)}</td>
                                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap text-center">{row.nom}</td>
                                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap text-center">{row.modele}</td>
                                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap text-center">{row.marque.nom}</td>
                                                    <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap text-center">{row.tarifs && row.tarifs[0] ? row.tarifs[0].ht : null} €</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

            </div>


        );
    }
}

export default linkProductAccessory;