import React, { Component } from 'react';
import Navbar from "../components/navbar"
import Profilebar from "../components/profilebar"


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataUser: this.props.dataUser
        }
    }

    render() {
        //console.log(process.env.REACT_APP_AuthAPI, this.state.dataUser)
        return (
            <>
                <Navbar where={"home"} dataUser={this.state.dataUser} />
                <div className="flex flex-col w-full">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className=" bg-gray-200 h-full">
                        <div class="transition-colors duration-300 flex justify-between bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Accueil </h3>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default Home