import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { initConfig } from "moduleauthazureapp"

async function initializeApp() {
  var msalConfig = await initConfig(process.env.REACT_APP_CLIENT_ID, process.env.REACT_APP_TENANT_ID, process.env.REACT_APP_REDIRECT_URI, process.env.REACT_APP_AzureGatewayAPI);

  const msalInstance = new PublicClientApplication(msalConfig);

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  );
}

initializeApp();