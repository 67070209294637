import React from 'react';
import Navbar from '../../components/navbar';
import Profilebar from "../../components/profilebar"
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import DynamicTable from "../../components/dynamicTable"
import LoadingTable from "../../components/loadingTable"
import LoadingBar from "../../components/loadBar"
import ButtonBack from "../../components/buttonBack"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import structureTableClim from '../../assets/table/structure_clim.json'
import structureTablePac from '../../assets/table/structure_pac.json'
import structureTableChaudiere from '../../assets/table/structure_chaudiere.json'
import structureTableAdoucisseur from '../../assets/table/structure_adoucisseur.json'
import structureTableChaudiereBois from '../../assets/table/structure_chaudierebois.json'
import structureTablePoelepelet from '../../assets/table/structure_poelepelet.json'
import structureTableThermodynamique from '../../assets/table/structure_thermodynamique.json'
import structureTableCumulus from '../../assets/table/structure_cumulus.json'
import structureTableAntitartres from '../../assets/table/structure_antitartres.json'
import structureTableReguls from '../../assets/table/structure_reguls.json'
import structureTableDesembouages from '../../assets/table/structure_desembouages.json'
import structureTablePots from '../../assets/table/structure_pots.json'
import structureDefault from '../../assets/table/structure_default.json'

class ListProductsOf extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            structureTable: this.getstructureTable(this.props.params),
            dataUser: this.props.dataUser,
            dataTable: null
        }
        this.refreshTable = this.refreshTable.bind(this)
        this.getDataTable = this.getDataTable.bind(this);
    }

    componentWillMount() {
        this.getDataTable()
    }

    componentDidUpdate() {
        if (this.props.params.categoryProduct && this.state.params != this.props.params) {
            this.setState({
                params: this.props.params,
                dataTable: null
            })
            this.getDataTable();
        }
    }

    async refreshTable() {
        await this.getDataTable()
    }

    async getDataTable() {
        var EcommAPI = process.env.REACT_APP_EcommAPI
        var tokenJWT = this.props.accessToken

        var getParamsTable = {
            "climatiseur": "clim",
            "pompe-a-chaleur": "pac",
            "chaudiere-gaz-fioul": "chaudiere",
            "adoucisseur": "adoucisseur",
            "chaudiere-a-bois": "chaudierebois",
            "poele-a-pellet": "poelepelet",
            "thermodynamique": "thermodynamique",
            "cumulus": "cumulus",
            "antitartres": "antitartre",
            "reguls": "reguls",
            "desembouages": "desembouages",
            "pots": "pots"
        }

        var config = {
            method: 'get',
            url: EcommAPI + '/api/produits/' + getParamsTable[this.props.params.categoryProduct] + '/all',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        var response = await axios(config)
        this.setState({
            dataTable: response.data
        });
    }

    getstructureTable(params) {
        const getstructureTable = {
            "climatiseur": structureTableClim,
            "pompe-a-chaleur": structureTablePac,
            "chaudiere-gaz-fioul": structureTableChaudiere,
            "adoucisseur": structureTableAdoucisseur,
            "chaudiere-a-bois": structureTableChaudiereBois,
            "poele-a-pellet": structureTablePoelepelet,
            "thermodynamique": structureTableThermodynamique,
            "cumulus": structureTableCumulus,
            "antitartres": structureTableAntitartres,
            "reguls": structureTableReguls,
            "desembouages": structureTableDesembouages,
            "pots": structureTablePots
        };

        //return getstructureTable[params.categoryProduct] || 0
        return structureDefault
    }

    canIDelete() {
        if (this.state.dataUser.roles.includes('ROLE_ECOMM:superadministrateur') ||
            this.state.dataUser.roles.includes('ROLE_ECOMM:administrateur')
        ) {
            return process.env.REACT_APP_EcommAPI + '/api/produits/'
        }
        else {
            return false
        }
    }

    canIEdit() {
        if (this.state.dataUser.roles.includes('ROLE_ECOMM:superadministrateur') ||
            this.state.dataUser.roles.includes('ROLE_ECOMM:administrateur') ||
            this.state.dataUser.roles.includes('ROLE_ECOMM:modificateur')
        ) {
            return "/edit-produits/" + this.state.params.categoryProduct + "/"
        }
        else {
            return false
        }
    }

    render() {
        const getTitre = {
            "climatiseur": "des climatiseurs",
            "pompe-a-chaleur": "des pompes à chaleur",
            "chaudiere-gaz-fioul": "des chaudières",
            "adoucisseur": "des adoucisseurs",
            "chaudiere-a-bois": "des chaudières à bois",
            "poele-a-pellet": "des poêles à pellet",
            "thermodynamique": "des thermodynamiques",
            "cumulus": "des cumulus",
            "antitartres": "des filtres anti-tartre",
            "reguls": "des régulateurs",
            "desembouages": "des désembouages",
            "pots": "des pots de récupération"
        };

        return (
            <>
                <Navbar where={"M-energies Solutions produits"} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-screen">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className=" flex flex-col bg-gray-200 h-full">
                        <div class="transition-colors duration-300 flex justify-between z-20 bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Liste {getTitre[this.props.params.categoryProduct]} <span className='text-sm'>({this.state.dataTable ? this.state.dataTable.length : 0})</span> </h3>
                            <div className='flex my-auto space-x-4'>
                                {this.state.dataUser.roles.includes('ROLE_ECOMM:superadministrateur') ||
                                    this.state.dataUser.roles.includes('ROLE_ECOMM:administrateur') ||
                                    this.state.dataUser.roles.includes('ROLE_ECOMM:modificateur')
                                    ? <Link to={"/nouveau-produit/" + this.state.params.categoryProduct} className="group mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded"><FontAwesomeIcon icon={faPlus} className="mr-1 group-hover:animate-pulse" />Ajouter</Link> : null}
                                <ButtonBack />
                            </div>
                        </div>
                        <div className='py-[1vw] px-10 flex flex-grow'>
                            {this.state.structureTable != 0 ?
                                this.state.dataTable
                                    ? <DynamicTable
                                        accessToken={this.props.accessToken}
                                        scrollFunction={this.scrollFunction}
                                        refreshTable={this.refreshTable}
                                        buttonDelete={this.canIDelete()}
                                        buttonEdit={this.canIEdit()}
                                        structureTable={this.state.structureTable}
                                        dataTable={this.state.dataTable}
                                        rounded={"false"} >
                                    </DynamicTable>
                                    : <LoadingTable numCol={10} numRow={19} rounded={"false"} />
                                : this.props.navigation('/404')
                            }
                        </div>
                    </div>
                </div>
                <div id='spinner' class="hidden min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="flex justify-center items-center h-full">
                        <img class="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
                    </div>
                </div>
            </>
        );
    }

}

export default (props) => (
    <ListProductsOf
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);