import React from 'react';
import Navbar from '../../components/navbar';
import FormAddProducts from '../../components/m-energies-solutions/formAddProducts';
import ButtonBack from "../../components/buttonBack"
import { useParams } from "react-router-dom";
import axios from 'axios';
import Profilebar from "../../components/profilebar"
import formClim from '../../assets/form/form_clim.json'
import formPac from '../../assets/form/form_pac.json'
import formChaudiere from '../../assets/form/form_chaudiere.json'
import formAdoucisseur from '../../assets/form/form_adoucisseur.json'
import formAntitartres from '../../assets/form/form_antitartres.json'
import formChaudiereBois from '../../assets/form/form_chaudierebois.json'
import formCumulus from '../../assets/form/form_cumulus.json'
import formDesembouages from '../../assets/form/form_desembouages.json'
import formPoelepelet from '../../assets/form/form_poelepelet.json'
import formPots from '../../assets/form/form_pots.json'
import formReguls from '../../assets/form/form_reguls.json'
import formThermodynamique from '../../assets/form/form_thermodynamique.json'

class EditProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            EcommAPI: process.env.REACT_APP_EcommAPI,
            tokenJWT: this.props.accessToken,
            form: this.getFrom(this.props.params),
            dataUser: this.props.dataUser,
            dataProduct: null
        }
        this.send = this.send.bind(this)

    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        var config = {
            method: 'get',
            url: this.state.EcommAPI + '/api/produits/infoForEdit/' + this.state.params.idProduct,
            headers: {
                'Authorization': 'Bearer ' + this.state.tokenJWT
            }
        };

        var response = await axios(config)
        this.setState({
            dataProduct: response.data
        });
    }

    getFrom(params) {

        const getFrom = {
            'climatiseur': formClim,
            'pompe-a-chaleur': formPac,
            'chaudiere-gaz-fioul': formChaudiere,
            'adoucisseur': formAdoucisseur,
            'antitartres': formAntitartres,
            'chaudiere-a-bois': formChaudiereBois,
            'cumulus': formCumulus,
            'desembouages': formDesembouages,
            'poele-a-pellet': formPoelepelet,
            'pots': formPots,
            'reguls': formReguls,
            'thermodynamique': formThermodynamique
        };

        return getFrom[params.categoryProduct] || 0
    }

    async send(data) {
        data = data.replaceAll('"[', '[')
        data = data.replaceAll(']"', ']')
        data = data.replaceAll('\\"', '"')
        console.log(data.replaceAll('"[', '['))

        var config = {
            method: 'put',
            url: this.state.EcommAPI + '/api/produits/' + this.state.params.idProduct,
            headers: {
                'Authorization': 'Bearer ' + this.state.tokenJWT,
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error.response.data);
            });

        window.location.replace("/tableau-des-produits/" + this.state.params.categoryProduct);

    }

    render() {
        const getTitre = {
            "climatiseur": "climatiseur",
            "pompe-a-chaleur": "pompe à chaleur",
            "chaudiere-gaz-fioul": "chaudière",
            "adoucisseur": "adoucisseur",
            "chaudiere-a-bois": "chaudière à bois",
            "poele-a-pellet": "poêle à pellet",
            "thermodynamique": "thermodynamique",
            "cumulus": "cumulus",
            "antitartres": "filtre anti-tartre",
            "reguls": "régulateurs",
            "desembouages": "désembouage",
            "pots": "pot de récupération"
        };

        return (
            <>
                <Navbar where={"M-energies Solutions " + this.state.params.categoryProduct} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-full">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className="  bg-gray-200 h-full">
                        <div class="transition-colors duration-300 flex justify-between z-20 bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Modification {getTitre[this.props.params.categoryProduct]} </h3>
                            <div className='flex my-auto space-x-4'>
                                <ButtonBack />
                            </div>
                        </div>
                        <div className='px-10 py-[1vw]'>
                            {this.state.form != 0 ?
                                this.state.dataProduct != null ? <FormAddProducts dataProduct={this.state.dataProduct} jsonForm={this.state.form} processData={this.send} accessToken={this.props.accessToken} ></FormAddProducts> : null
                                : this.props.navigation('/404')
                            }
                        </div>

                    </div>
                </div>
            </>
        );
    }

};

export default (props) => (
    <EditProduct
        {...props}
        params={useParams()}
    />
);