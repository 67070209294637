import React from 'react';
import Navbar from '../../components/navbar';
import Profilebar from "../../components/profilebar"
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons'
import structureTableAgence from '../../assets/table/structure_agence.json'
import NotifBar from '../../components/notifBar'
import ButtonBack from "../../components/buttonBack"

class NewAgences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            structureTable: structureTableAgence,
            dataUser: this.props.dataUser,
            dataTable: null,
            displayAlert: false
        }
        this.newAgence = this.newAgence.bind(this)
    }

    displayAlert(messageAlert) {
        this.setState({
            displayAlert: !this.state.displayAlert,
            messageAlert: messageAlert
        })
        setTimeout(() => {
            this.setState({
                displayAlert: !this.state.displayAlert
            })
        }, 6000);
    }

    async newAgence(event) {
        event.preventDefault();
        var AgenceAPI = process.env.REACT_APP_AgenceAPI
        var tokenJWT = this.props.accessToken
        const formData = new FormData(event.target);
        const dataAgence = {};

        for (const [name, value] of formData.entries()) {
            dataAgence[name] = value;
        }

        var config = {
            method: 'post',
            url: AgenceAPI + '/api/agences',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            },
            data: dataAgence
        };

        var agence = await axios(config)


        if (agence.status == 200) {
            this.props.navigation('/detail-agence/' + agence.data.id)
        } else {
            this.displayAlert("Une erreur est survenue " + agence.message)
        }
    }

    render() {
        return (
            <>
                <Navbar where={"agences"} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-screen">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className=" bg-gray-200 h-full flex flex-col space-y-[1vw]">
                        <div class="transition-colors duration-300 flex justify-between z-20 bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Nouvelle agence</h3>
                            <div className='flex my-auto space-x-4'>
                                <ButtonBack />
                            </div>
                        </div>
                        {
                            this.state.displayAlert ? <div className='flex mx-auto w-fit mb-2'>
                                <NotifBar message={this.state.messageAlert} color="red" />
                            </div> : null
                        }
                        <form class="  flex flex-col bg-white rounded px-12 py-6 border border-gray-300 mx-auto  w-2/3 space-y-5" id="coordonnes" onSubmit={this.newAgence}>
                            <h2 className='font-medium '>Renseigner les informations de la nouvelle agence </h2>
                            <div className='space-y-2'>
                                <p className='flex'><span className='w-1/6'> Libellé :</span> <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-5/6" name="libelle" placeholder='M ENERGIES SERVICE Nancy' required /> </p>
                                <p className='flex'><span className='w-1/6'> Nom :</span> <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-5/6" name="nom" placeholder='MES 54' required /> </p>
                                <p className='flex'><span className='w-1/6'> Adresse mail :</span> <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-5/6" name="email" placeholder='agence.nancy1@m-energies-service.fr' required /> </p>
                                <p className='flex'><span className='w-1/6'> Contact mail :</span> <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-5/6" name="distributionList" placeholder='contactmes54@m-energies.net' required /> </p>
                                <p className='flex'><span className='w-1/6'> Téléphone :</span> <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-5/6" name="telephone" placeholder='0383203261' required />  </p>
                                <p className='flex'>
                                    <span className='w-1/6'> Adresse postal :</span>
                                    <div className='flex space-x-1 w-5/6'>
                                        <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-1/12" name="numero" placeholder='96' required />
                                        <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-1/12" name="quaru" placeholder='impasse' required />
                                        <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-5/12" name="rue" placeholder='Pierre et Marie Curie ' required />
                                        <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-1/12" name="codepostal" placeholder='54710' required />
                                        <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-2/12" name="ville" placeholder='Ludres' required />
                                        <input type={"text"} className="bg-white border rounded px-2 py-1 border bg-gray-200 border-gray-300 w-2/12" name="pays" placeholder='France' required />
                                    </div>
                                </p>
                            </div>

                            <button type='submit' className="flex group mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded w-min ml-auto">
                                <svg class="w-5 h-5 flex items-center cursor-pointer text-white mx-auto mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <FontAwesomeIcon icon={faSave} />
                                </svg>
                                Enregistrer
                            </button>
                        </form>
                    </div>
                </div>
                <div id='spinner' class="hidden min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="flex justify-center items-center h-full">
                        <img class="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
                    </div>
                </div>
            </>
        );
    }

}

export default (props) => (
    <NewAgences
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);