import React from 'react';
import Navbar from '../../components/navbar';
import Profilebar from "../../components/profilebar"
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import DynamicTable from "../../components/dynamicTable"
import LoadingTable from "../../components/loadingTable"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faInfo } from '@fortawesome/free-solid-svg-icons'
import structureTableAgence from '../../assets/table/structure_agence.json'


class ListAgences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            structureTable: structureTableAgence,
            dataUser: this.props.dataUser,
            dataTable: null
        }
        this.refreshTable = this.refreshTable.bind(this)
        this.getDataTable = this.getDataTable.bind(this);
        this.deleteAgence = this.deleteAgence.bind(this)
    }

    componentWillMount() {
        this.getDataTable()
    }

    componentDidUpdate() {
        if (this.state.params != this.props.params) {
            //console.log(this.props.params);
            this.setState({
                params: this.props.params,
                dataTable: null
            })
            this.getDataTable();
        }
    }

    async refreshTable() {
        await this.getDataTable()
    }

    async getDataTable() {
        var AgenceAPI = process.env.REACT_APP_AgenceAPI
        var tokenJWT = this.props.accessToken

        var config = {
            method: 'get',
            url: AgenceAPI + '/api/agences/l',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        var response = await axios(config)
        this.setState({
            dataTable: response.data
        });

    }

    canIDetailEditDelete() {
        if (this.state.dataUser.roles.some(role => ['ROLE_AGENCE:superadministrateur', 'ROLE_AGENCE:administrateur'].includes(role))) {
            return 2
        } else {
            return 1
        }
    }

    openPopUp(idProductToDelete) {
        document.getElementById('inputVerification').value = null;
        document.getElementById('popup').classList.remove('hidden')
        document.body.classList.add('overflow-hidden')
        document.getElementById('messageErrorInput').classList.add('hidden')
        document.getElementById('idProductToDelete').value = idProductToDelete;
    }

    closePopUp() {
        document.getElementById('popup').classList.add('hidden')
        document.body.classList.remove('overflow-hidden')
        document.getElementById('messageErrorInput').classList.add('hidden')
        document.getElementById('idProductToDelete').value = null;
        document.getElementById('inputVerification').value = null;
    }

    async deleteAgence() {
        let valueVerification = document.getElementById('inputVerification').value;
        if (valueVerification === "SUPPRIMER") {
            var tokenJWT = this.props.accessToken

            var config = {
                method: 'delete',
                url: process.env.REACT_APP_AgenceAPI + '/api/agences/' + document.getElementById('idProductToDelete').value,
                headers: {
                    'Authorization': 'Bearer ' + tokenJWT,
                    'Content-Type': 'application/json'
                }
            };
            document.getElementById('popup').classList.add('hidden')
            document.getElementById('spinner').classList.remove('hidden')
            document.body.classList.add('overflow-hidden')

            await axios(config)
            await this.refreshTable()

            document.getElementById('spinner').classList.add('hidden')
            document.body.classList.remove('overflow-hidden')
        } else {
            document.getElementById('messageErrorInput').classList.remove('hidden')
        }
    }



    render() {
        return (
            <>
                <Navbar where={"agences"} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-screen">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className=" bg-gray-200 h-full flex flex-col">
                        <div class="transition-colors duration-300 flex justify-between bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Liste agences <span className='text-sm'>({this.state.dataTable ? this.state.dataTable.length : 0})</span></h3>
                            <div className='flex my-auto space-x-4'>
                                {this.state.dataUser.roles.some(role => ['ROLE_AGENCE:superadministrateur', 'ROLE_AGENCE:administrateur', 'ROLE_AGENCE:modificateur'].includes(role))
                                    ? <Link to={"/nouvelle-agence"} className="group ml-auto mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer"><FontAwesomeIcon icon={faPlus} className="mr-1 group-hover:animate-pulse" />Ajouter</Link> : null}
                            </div>
                        </div>

                        <div className='grid grid-cols-5 gap-8 overflow-auto ecran2:max-h-[39vw] max-h-[35vw] px-10 my-[1vw]'>
                            {this.state.dataTable
                                ? this.state.dataTable.map((data) =>
                                    <div className='flex flex-col  '>
                                        <div className='px-6 py-3 bg-white rounded-t h-full'>
                                            <p className='text-xl font-medium'> {data.nom} </p>
                                            {data.adresse ? <>
                                                <p>
                                                    {data.adresse.numero} {data.adresse.rue}
                                                </p>
                                                <p>
                                                    {data.adresse.codepostal} {data.adresse.ville}
                                                </p></>
                                                : null}
                                        </div>
                                        <div className={'bg-gray-300 text-gray-500 rounded-b text-center py-2 flex grid grid-cols-' + this.canIDetailEditDelete()}>
                                            <Link to={"/detail-agence/" + data.id} >
                                                <svg class="w-5 h-5 flex items-center cursor-pointer text-gray-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                                    <FontAwesomeIcon icon={faInfo} />
                                                </svg>
                                            </Link>


                                            {this.state.dataUser.roles.some(role => ['ROLE_AGENCE:superadministrateur', 'ROLE_AGENCE:administrateur'].includes(role))
                                                ? <div onClick={() => this.openPopUp(data.id)} className='cursor-pointer'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 flex items-center  text-gray-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                )
                                : null}

                        </div>
                    </div>
                </div >
                <div id='spinner' class="hidden min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="flex justify-center items-center h-full">
                        <img class="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
                    </div>
                </div>
                <div id="popup" class="min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div onClick={this.closePopUp} class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="w-full flex flex-col max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                        <div class="text-center p-5 flex-auto justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 -m-1 flex items-center text-red-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-16 h-16 flex items-center text-red-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                            <h2 class="text-xl font-bold py-4 ">Etes-vous sûrs ?</h2>
                            <p class="text-sm text-gray-500 px-8">Si vous souhaitez réellement supprimer écrivez SUPPRIMER. Ce processus est irréversible.</p>
                        </div>
                        <samp id='messageErrorInput' class='mx-auto text-red-500 hidden'>Saisie incorrect </samp>
                        <input id='inputVerification' type='text' class="border border-gray-300 p-2 my-2 rounded-md focus:outline-none focus:ring-2 ring-blue-200 mx-auto" />
                        <input id='idProductToDelete' type='hidden' />
                        <div class="p-3  mt-2 text-center space-x-4 md:block">
                            <button onClick={this.closePopUp} class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                Annuler
                            </button>
                            <button onClick={this.deleteAgence} class="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-red-600">
                                Suprimer
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default (props) => (
    <ListAgences
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);