import React, { Component } from 'react';
import Navbar from "../components/navbar"
import Profilebar from "../components/profilebar"

class Profile extends Component {
    constructor(props) {

        super(props);
        this.state = {
            user: this.props.dataUser,
            tokenJWT: this.props.accessToken
        }
    }

    componentDidMount() {
    }

    render() {
        //console.log(this.props.accessToken)
        return (
            <>
                <Navbar where={"home"} dataUser={this.state.user} />
                <div className="flex flex-col w-full">
                    <Profilebar username={this.state.user.userName} />
                    <div className="px-[3vw] py-[1vw] bg-gray-200 h-full">
                        <h1 className='mb-5 text-xl'> Profile </h1>
                        {this.state.user ? <div> <p><strong>First Name: {this.state.user.firstName} </strong> </p>
                            <p><strong>Last Name: {this.state.user.name} </strong> </p>
                            <p><strong>FullName: {this.state.user.fullName} </strong> </p>
                            <p><strong>Email: {this.state.user.mail} </strong> </p>
                            <p><strong>Roles:</strong>
                                <ul class="list-disc list-inside">
                                    {this.props.dataUser.roles.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </p>
                            <p className='break-all'><strong>TokenJWT: </strong> {this.state.tokenJWT}  </p>
                        </div>
                            : null
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default (props) => (
    <Profile
        {...props}
    />
);
