import React from 'react';

const notFound = () => {
  return (
    <div class="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20 mb-12">
        <main class="sm:flex">
          <p class="text-4xl font-extrabold text-black sm:text-5xl">
            404
          </p>
          <div class="sm:ml-6">
            <div class="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Page introuvable
              </h1>
              <p class="mt-1 text-base text-gray-500">
              </p>
            </div>
            <div class="mt-10 flex space-x-1 sm:border-l sm:border-transparent sm:pl-6">
              <a href="/"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-black hover:bg-gray-800 focus:outline-none ">
                Retour à l’accueil
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default notFound;