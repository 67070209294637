import React from 'react';
import Navbar from '../../components/navbar';
import Profilebar from "../../components/profilebar"
import { useParams, useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInfo } from '@fortawesome/free-solid-svg-icons'
import CleanModelMarkByCustomer from '../../components/integration/cleanModelMarkByCustomer'
import CleanAdressByCustomer from '../../components/integration/cleanAdressByCustomer'


class Integration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            dataUser: this.props.dataUser,
        }

    }


    render() {
        return (
            <>
                <Navbar where={"secteur"} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-screen">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className="px-[3vw] py-[1vw] bg-gray-200 h-full flex flex-col">
                        <div className='flex'>
                            <h1 className='mb-5 text-xl'>Integration </h1>

                        </div>
                        <div className='grid grid-cols-2 gap-8 max-h-[40vw] pr-2'>
                            <CleanModelMarkByCustomer accessToken={this.props.accessToken} dataUser={this.props.dataUser} />
                            <CleanAdressByCustomer accessToken={this.props.accessToken} dataUser={this.props.dataUser} />
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default (props) => (
    <Integration
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);