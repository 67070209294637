import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import NavAddProduct from "./m-energies-solutions/navAddProduct"
import NavAddAgence from "./agence/navAddAgence"

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            where: this.props.where,
            dataUser: this.props.dataUser
        }
    }
    openNav(idNav, idChevron) {
        if (document.getElementById(idNav).classList.contains("opacity-0")) {
            document.getElementById(idChevron).classList.add("rotate-180")
            document.getElementById(idNav).classList.remove("h-0")
            document.getElementById(idNav).classList.remove("opacity-0")
            document.getElementById(idNav).classList.add("opacity-100")
            document.getElementById(idNav).classList.remove("hidden")
        } else {
            document.getElementById(idChevron).classList.remove("rotate-180")
            document.getElementById(idNav).classList.add("opacity-0")
            document.getElementById(idNav).classList.remove("opacity-100")
            document.getElementById(idNav).classList.add("h-0")
            document.getElementById(idNav).classList.add("hidden")

        }
    }

    render() {
        return (
            <div className="flex flex-col navbar-light  min-h-screen  min-w-[240px] max-w-[240px] text-white">
                <NavLink to="/home" className="flex cursor-pointer px-2 bg-gray-900 h-[64px] font-bold ">
                    <h1 className=" text-xl uppercase m-auto">
                        Web Admin
                    </h1>
                </NavLink>
                <nav className="flex flex-col text-sm leading-6 pb-2 text-gray-600">
                    <NavLink to="/home" className={"text-gray-600 hover:border-l-[#111827] hover:text-[#111827] hover:bg-gray-50  group flex px-4 py-1.5 leading-6  border-x-4 border-r-transparent " + (this.props.where == "home" ? " bg-gray-50 border-l-[#111827] text-[#111827]" : "border-l-transparent")}>
                        <FontAwesomeIcon icon={faCog} className={" my-auto group-hover:text-[#111827] " + (this.props.where == "home" ? "text-[#111827]" : "text-gray-400")} />
                        <span className='ml-3 my-auto'>Accueil</span>
                    </NavLink>

                    {
                        ['ROLE_ECOMM:superadministrateur', 'ROLE_ECOMM:administrateur', 'ROLE_ECOMM:modificateur', 'ROLE_ECOMM:lecteur'].some(role => this.state.dataUser.roles.includes(role))
                            ? <>
                                <div onClick={() => this.openNav("navMenergiesSolutions", "chevronMenergiesSolutions")}
                                    className={"text-gray-600 hover:border-l-[#111827] hover:text-[#111827] hover:bg-gray-50  group flex px-4 py-1.5 leading-6  border-x-4 border-r-transparent cursor-pointer " +
                                        (this.props.where == "M-energies Solutions produits" || this.props.where == "M-energies Solutions contrat-entretien"
                                            ? " bg-gray-50 border-l-[#111827] text-[#111827]"
                                            : " border-l-transparent")
                                    }>
                                    <FontAwesomeIcon icon={faCog} className={" my-auto " + ((this.props.where == "M-energies Solutions produits" || this.props.where == "M-energies Solutions contrat-entretien") ? "text-[#111827]" : "text-gray-400")} /> <span className='ml-3 my-auto'> M-energies Solutions </span>
                                    <FontAwesomeIcon icon={faChevronDown} id='chevronMenergiesSolutions' className={"transition duration-200  my-auto ml-auto " + ((this.props.where == "M-energies Solutions produits" || this.props.where == "M-energies Solutions contrat-entretien") ? "text-[#111827]" : "text-gray-400")} />
                                </div>
                                <div id='navMenergiesSolutions' className='hidden h-0 opacity-0 transition duration-200 flex flex-col '>
                                    <NavLink to="/tableau-des-produits" className={"text-gray-600 hover:border-l-[#111827] hover:text-[#111827] hover:bg-gray-50  group flex px-4 py-1.5 leading-6  border-x-4 border-r-transparent " + (this.props.where == "M-energies Solutions produits" ? " bg-gray-50 border-l-[#111827] text-[#111827]t" : "border-l-transparent")}>
                                        <span className='ml-6 my-auto'>Produits</span>
                                    </NavLink>
                                    <NavLink to="/contrat-entretien" className={"text-gray-600 hover:border-l-[#111827] hover:text-[#111827] hover:bg-gray-50  group flex px-4 py-1.5 leading-6  border-x-4 border-r-transparent " + (this.props.where == "M-energies Solutions contrat-entretien" ? " bg-gray-50 border-l-[#111827] text-[#111827]" : "border-l-transparent")}>
                                        <span className='ml-6 my-auto'>Contrat d'entretien</span>
                                    </NavLink>
                                </div>
                            </>
                            : null
                    }
                    {
                        ['ROLE_AGENCE:superadministrateur', 'ROLE_AGENCE:administrateur', 'ROLE_AGENCE:modificateur', 'ROLE_AGENCE:lecteur'].some(role => this.state.dataUser.roles.includes(role)) ?
                            <>
                                <NavLink to="/tableau-des-agences" className={"text-gray-600 hover:border-l-[#111827] hover:text-[#111827] hover:bg-gray-50  group flex px-4 py-1.5 leading-6  border-x-4 border-r-transparent " + (this.props.where == "agences" ? " bg-gray-50 border-l-[#111827] text-[#111827]" : "border-l-transparent")}>
                                    <FontAwesomeIcon icon={faCog} className={" my-auto group-hover:text-[#111827] " + (this.props.where == "agences" ? "text-[#111827]" : "text-gray-400")} />
                                    <span className='ml-3 my-auto'>Agences</span>
                                    {['ROLE_AGENCE:superadministrateur', 'ROLE_AGENCE:administrateur', 'ROLE_AGENCE:modificateur'].some(role => this.state.dataUser.roles.includes(role))
                                        ? <NavAddAgence dataUser={this.state.dataUser} path="/nouvelle-agence"></NavAddAgence>
                                        : null}
                                </NavLink>
                                <NavLink to="/secteur" className={"text-gray-600 hover:border-l-[#111827] hover:text-[#111827] hover:bg-gray-50  group flex px-4 py-1.5 leading-6  border-x-4 border-r-transparent " + (this.props.where == "secteur" ? " bg-gray-50 border-l-[#111827] text-[#111827]" : "border-l-transparent")} >
                                    <FontAwesomeIcon icon={faCog} className={" my-auto group-hover:text-[#111827] " + (this.props.where == "secteur" ? "text-[#111827]" : "text-gray-400")} />
                                    <span className='ml-3 my-auto'>Secteur</span>
                                </NavLink>
                            </>
                            : null
                    }
                    {
                        ['ROLE_PARTENAIRE:superadministrateur', 'ROLE_PARTENAIRE:administrateur', 'ROLE_PARTENAIRE:modificateur', 'ROLE_PARTENAIRE:lecteur'].some(role => this.state.dataUser.roles.includes(role)) ?
                            <NavLink to="/partenaire" className={"text-gray-600 hover:border-l-[#111827] hover:text-[#111827] hover:bg-gray-50  group flex px-4 py-1.5 leading-6  border-x-4 border-r-transparent " + (this.props.where == "partenaire" ? " bg-gray-50 border-l-[#111827] text-[#111827]" : "border-l-transparent")} >
                                <FontAwesomeIcon icon={faCog} className={" my-auto group-hover:text-[#111827] " + (this.props.where == "partenaire" ? "text-[#111827]" : "text-gray-400")} />
                                <span className='ml-3 my-auto'>Partenaire</span>
                            </NavLink>
                            : null
                    }
                </nav>
            </div >
        );
    }
}

export default (props) => (
    <Navbar
        {...props}
        navigation={useNavigate()}
    />
);