import React from 'react';
import Navbar from '../../components/navbar';
import Profilebar from "../../components/profilebar"
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import DynamicTable from "../../components/dynamicTable"
import LoadingTable from "../../components/loadingTable"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faSave } from '@fortawesome/free-solid-svg-icons'
import structureTableAgence from '../../assets/table/structure_agence.json'
import NotifBar from '../../components/notifBar'

class MaintenanceContract extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            structureTable: structureTableAgence,
            dataUser: this.props.dataUser,
            dataTable: null,
            messageAlert: null
        }
        this.refreshTable = this.refreshTable.bind(this)
        this.getDataTable = this.getDataTable.bind(this);
        this.editPrice = this.editPrice.bind(this)
    }

    componentDidMount() {
        this.getDataTable()
    }

    componentDidUpdate() {
        if (this.state.params != this.props.params) {
            console.log(this.props.params);
            this.setState({
                params: this.props.params,
                dataTable: null
            })
            this.getDataTable();
        }
    }

    async refreshTable() {
        await this.getDataTable()
    }

    async getDataTable() {
        var EcommAPI = process.env.REACT_APP_EcommAPI
        var tokenJWT = this.props.accessToken

        var config = {
            method: 'get',
            url: EcommAPI + '/api/contrats/all',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        var response = await axios(config)
        this.setState({
            dataTable: response.data
        });

    }

    displayAlert(messageAlert) {
        this.setState({
            displayAlert: !this.state.displayAlert,
            messageAlert: messageAlert
        })
        setTimeout(() => {
            this.setState({
                displayAlert: !this.state.displayAlert
            })
        }, 6000);
    }

    editPrice(contrat, key) {
        //console.log(contrat.id)
        if (this.state["editRow" + contrat.id]) {
            var EcommAPI = process.env.REACT_APP_EcommAPI
            var tokenJWT = this.props.accessToken

            var data = {}
            for (let i = 1; i <= 4; i++) {
                data["prix" + i] = document.getElementById("prix" + i + "_" + contrat.id).value
            }

            var config = {
                method: 'put',
                url: EcommAPI + '/api/contrats/' + contrat.id,
                headers: {
                    'Authorization': 'Bearer ' + tokenJWT
                },
                data: data
            };
            axios(config)
                .then((response) => {
                    let listContrat = this.state.dataTable
                    for (let i = 1; i <= 4; i++) {
                        listContrat[key]["prix" + i] = data["prix" + i]
                    }
                    this.setState({
                        dataTable: listContrat
                    })
                })
                .catch((error) => {
                    this.displayAlert(error.message || error.error || "Une erreur est survenue durant l'enregistreement des données ")
                })
        }

        this.setState({
            ["editRow" + contrat.id]: !this.state["editRow" + contrat.id]
        })
    }

    render() {
        const typeEnergie = {
            pac: "pompe à chaleur",
            gaz: "chaudière à gaz",
            fioul: "chaudière à fioul",
            clim: "climatiseur",
            ce: "chauffe eau thermodynamique",
            "pac+ce": "Pompe à chaleur + chauffe eau thermodynamique",
            "pac+h": "Pompe à chaleur hybride",
        };

        return (
            <>
                <Navbar where={"M-energies Solutions contrat-entretien"} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-screen">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className=" bg-gray-200 h-full flex flex-col">
                        <div className='flex'>
                            <div class="transition-colors duration-300 flex justify-between bg-gray-700 w-full bg-opacity-10 h-[64px] px-10 ">
                                <h3 class="text-gray-[#111827]] text-lg my-auto"> Liste des contrats d'entretien <span className='text-sm'>({this.state.dataTable ? this.state.dataTable.length : 0}) </span> </h3>
                            </div>
                        </div>
                        <div className="px-[3vw] py-[1vw]">
                            <div className={`max-w-[84vw] not-prose relative bg-grid-gray-100 bg-gray-50 h-full overflow-auto ecran2:max-h-[39vw]  max-h-[35vw]`} >
                                <table className="border-collapse table-auto w-full text-sm">
                                    <thead className='w-full text-gray-500'>
                                        <tr>
                                            {this.state.dataUser.roles.some(role => ['ROLE_ECOMM:superadministrateur', 'ROLE_ECOMM:administrateur', 'ROLE_ECOMM:modificateur'].includes(role))
                                                ? <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b p-5 tracking-wider uppercase text-xs text-left border-transparent `}> </th >
                                                : null}
                                            <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `}> Type d'energie</th >
                                            <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `}> Production d'eau chaude</th >
                                            <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `}> Type de chaudière</th >
                                            <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `}> Année de logement</th >
                                            <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `}> Prix 1 ttc</th >
                                            <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `}> Prix 2 ttc</th >
                                            <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `}> Prix 3 ttc</th >
                                            <th className={`bg-gray-300 whitespace-nowrap z-10 sticky top-0 border-b px-3 py-4 tracking-wider uppercase text-xs text-left border-transparent `}> Prix 4 ttc</th >
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            this.state.dataTable ?
                                                this.state.dataTable.map((row, key) =>
                                                    <tr key={key} className={`hover:bg-gray-100 text-gray-500 `} >
                                                        {this.state.dataUser.roles.some(role => ['ROLE_ECOMM:superadministrateur', 'ROLE_ECOMM:administrateur', 'ROLE_ECOMM:modificateur'].includes(role))
                                                            ? <td >
                                                                <div onClick={() => { this.editPrice(row, key) }} >
                                                                    {this.state["editRow" + row.id]
                                                                        ? <svg class="w-5 h-5 flex items-center cursor-pointer text-blue-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                                                            <FontAwesomeIcon icon={faSave} />
                                                                        </svg>
                                                                        : <svg class="w-5 h-5 flex items-center cursor-pointer text-gray-500 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </svg>
                                                                    }
                                                                </div>
                                                            </td>
                                                            : null}
                                                        <td className="border-b border-gray-100 px-3 py-2 text-left"> {typeEnergie[row.typeEnergie] || row.typeEnergie}</td>
                                                        <td className="border-b border-gray-100 px-3 py-2 text-left"> {row.eauChaude == 1 ? "oui" : "non"} </td>
                                                        <td className="border-b border-gray-100 px-3 py-2 text-left"> {row.typeChaudiere != "null" ? row.typeChaudiere : null} </td>
                                                        <td className="border-b border-gray-100 px-3 py-2 text-left"> {row.aneeLogement} ans </td>
                                                        {[1, 2, 3, 4].map((prix) => (
                                                            <td className="border-b border-gray-100 px-3 py-2 text-left" >
                                                                {this.state["editRow" + row.id] ? (
                                                                    <input type={"text"} className="bg-white border rounded px-1 py-0.5 border bg-gray-200 border-gray-300 w-[3vw]" defaultValue={row["prix" + prix]} id={"prix" + prix + "_" + row.id} />
                                                                ) : (
                                                                    row["prix" + prix] + " €"
                                                                )}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ) : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.displayAlert ?
                        <div id='notifBar' class="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover "  >
                            <div className='mx-auto h-2/3 my-auto'>
                                <NotifBar message={this.state.messageAlert || null} color="red" />
                            </div>
                        </div>
                        : null}
            </>
        );
    }

}

export default (props) => (
    <MaintenanceContract
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);