import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faFileImport, faFileExcel, faXmark, faInfo } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import NotifBar from '../../components/notifBar'

class ImportExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            where: this.props.where,
            dataUser: this.props.dataUser,
            messageErrorInput: null,
            importSucces: false,
            departements: [
                { "code": "01", "nom": "Ain" },
                { "code": "02", "nom": "Aisne" },
                { "code": "03", "nom": "Allier" },
                { "code": "04", "nom": "Alpes-de-Haute-Provence" },
                { "code": "05", "nom": "Hautes-Alpes" },
                { "code": "06", "nom": "Alpes-Maritimes" },
                { "code": "07", "nom": "Ardèche" },
                { "code": "08", "nom": "Ardennes" },
                { "code": "09", "nom": "Ariège" },
                { "code": "10", "nom": "Aube" },
                { "code": "11", "nom": "Aude" },
                { "code": "12", "nom": "Aveyron" },
                { "code": "13", "nom": "Bouches-du-Rhône" },
                { "code": "14", "nom": "Calvados" },
                { "code": "15", "nom": "Cantal" },
                { "code": "16", "nom": "Charente" },
                { "code": "17", "nom": "Charente-Maritime" },
                { "code": "18", "nom": "Cher" },
                { "code": "19", "nom": "Corrèze" },
                { "code": "2A", "nom": "Corse-du-Sud" },
                { "code": "2B", "nom": "Haute-Corse" },
                { "code": "21", "nom": "Côte-d'Or" },
                { "code": "22", "nom": "Côtes-d'Armor" },
                { "code": "23", "nom": "Creuse" },
                { "code": "24", "nom": "Dordogne" },
                { "code": "25", "nom": "Doubs" },
                { "code": "26", "nom": "Drôme" },
                { "code": "27", "nom": "Eure" },
                { "code": "28", "nom": "Eure-et-Loir" },
                { "code": "29", "nom": "Finistère" },
                { "code": "30", "nom": "Gard" },
                { "code": "31", "nom": "Haute-Garonne" },
                { "code": "32", "nom": "Gers" },
                { "code": "33", "nom": "Gironde" },
                { "code": "34", "nom": "Hérault" },
                { "code": "35", "nom": "Ille-et-Vilaine" },
                { "code": "36", "nom": "Indre" },
                { "code": "37", "nom": "Indre-et-Loire" },
                { "code": "38", "nom": "Isère" },
                { "code": "39", "nom": "Jura" },
                { "code": "40", "nom": "Landes" },
                { "code": "41", "nom": "Loir-et-Cher" },
                { "code": "42", "nom": "Loire" },
                { "code": "43", "nom": "Haute-Loire" },
                { "code": "44", "nom": "Loire-Atlantique" },
                { "code": "45", "nom": "Loiret" },
                { "code": "46", "nom": "Lot" },
                { "code": "47", "nom": "Lot-et-Garonne" },
                { "code": "48", "nom": "Lozère" },
                { "code": "49", "nom": "Maine-et-Loire" },
                { "code": "50", "nom": "Manche" },
                { "code": "51", "nom": "Marne" },
                { "code": "52", "nom": "Haute-Marne" },
                { "code": "53", "nom": "Mayenne" },
                { "code": "54", "nom": "Meurthe-et-Moselle" },
                { "code": "55", "nom": "Meuse" },
                { "code": "56", "nom": "Morbihan" },
                { "code": "57", "nom": "Moselle" },
                { "code": "58", "nom": "Nièvre" },
                { "code": "59", "nom": "Nord" },
                { "code": "60", "nom": "Oise" },
                { "code": "61", "nom": "Orne" },
                { "code": "62", "nom": "Pas-de-Calais" },
                { "code": "63", "nom": "Puy-de-Dôme" },
                { "code": "64", "nom": "Pyrénées-Atlantiques" },
                { "code": "65", "nom": "Hautes-Pyrénées" },
                { "code": "66", "nom": "Pyrénées-Orientales" },
                { "code": "67", "nom": "Bas-Rhin" },
                { "code": "68", "nom": "Haut-Rhin" },
                { "code": "69", "nom": "Rhône" },
                { "code": "70", "nom": "Haute-Saône" },
                { "code": "71", "nom": "Saône-et-Loire" },
                { "code": "72", "nom": "Sarthe" },
                { "code": "73", "nom": "Savoie" },
                { "code": "74", "nom": "Haute-Savoie" },
                { "code": "75", "nom": "Paris" },
                { "code": "76", "nom": "Seine-Maritime" },
                { "code": "77", "nom": "Seine-et-Marne" },
                { "code": "78", "nom": "Yvelines" },
                { "code": "79", "nom": "Deux-Sèvres" },
                { "code": "80", "nom": "Somme" },
                { "code": "81", "nom": "Tarn" },
                { "code": "82", "nom": "Tarn-et-Garonne" },
                { "code": "83", "nom": "Var" },
                { "code": "84", "nom": "Vaucluse" },
                { "code": "85", "nom": "Vendée" },
                { "code": "86", "nom": "Vienne" },
                { "code": "87", "nom": "Haute-Vienne" },
                { "code": "88", "nom": "Vosges" },
                { "code": "89", "nom": "Yonne" },
                { "code": "90", "nom": "Territoire de Belfort" },
                { "code": "91", "nom": "Essonne" },
                { "code": "92", "nom": "Hauts-de-Seine" },
                { "code": "93", "nom": "Seine-Saint-Denis" },
                { "code": "94", "nom": "Val-de-Marne" },
                { "code": "95", "nom": "Val-d'Oise" },
                { "code": "971", "nom": "Guadeloupe" },
                { "code": "972", "nom": "Martinique" }
            ]

        }
        this.convertCsvToJson = this.convertCsvToJson.bind(this)
        this.exportSecteurs = this.exportSecteurs.bind(this)
        this.importSecteurs = this.importSecteurs.bind(this)
        this.dropHandler = this.dropHandler.bind(this)
        this.openPopUp = this.openPopUp.bind(this)
        this.closePopUp = this.closePopUp.bind(this)
        this.displayMessageErrorImport = this.displayMessageErrorImport.bind(this)
    }

    exportSecteurs(ev) {
        ev.preventDefault();
        const AgenceAPI = process.env.REACT_APP_AgenceAPI
        const tokenJWT = this.props.accessToken
        const departements = ev.target.departements.value

        var config = {
            method: 'get',
            url: AgenceAPI + '/api/secteurs/exports/' + departements,
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        axios(config).then(response => {
            var headerClean = ['codepostal', 'id_agence', 'libelle', 'activite']
            this.convertJsonToCsv(response.data, departements ? ("Export_secteurs_" + departements) : "Export_secteurs", null, headerClean)
        })

    }

    recupererHeaders(val, array = [], parent = null) {
        for (var j in val) {
            if (typeof val[j] == 'object') {
                if (Array.isArray(val[j])) {
                    val[j].map((vals, key) => {
                        this.recupererHeaders(vals, array, (parent ? parent + "." : "") + j + "[" + key + "]")
                    })
                } else {
                    this.recupererHeaders(val[j], array, (parent ? parent + "." : "") + j)
                }
            } else {
                array.push(parent ? parent + "." + j : j)
            }
        }
        return array
    }

    convertJsonToCsv(jsonTable, fileName, header = null, headerClean = null) {
        var items = jsonTable
        var replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        try {
            var arr = jsonTable.map(vals => JSON.stringify(vals).split('":"').length)
            var key = arr.indexOf(Math.max.apply(null, arr))
            header = header == null ? this.recupererHeaders(jsonTable[key]) : header

            var csv = [
                headerClean == null ? header.join(';') : headerClean.join(';'), // header row first
                ...items.map(row => header.map((fieldName) => { try { return JSON.stringify(eval("row." + fieldName), replacer) } catch (e) { return null } }).join(';'))
            ].join('\n')
        } catch (error) {
            console.log(error)
            //this.displayAlert("Une erreur est survenue lorsque de la conversion des données", "red")
            //document.getElementById('spinner').classList.add("hidden")
        }

        this.downloadString(csv, "text/csv", fileName)
    }

    downloadString(text, fileType, fileName) {
        try {
            var blob = new Blob([text], { type: fileType });
            var a = document.createElement('a');
            a.download = fileName;
            a.href = URL.createObjectURL(blob);
            a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () { URL.revokeObjectURL(a.href); }, 1500);
            //document.getElementById('spinner').classList.add("hidden")
        } catch (error) {
            console.log(error)
            //this.displayAlert("Une erreur est survenue lorsque de l'envoie des données", "red")
            //document.getElementById('spinner').classList.add("hidden")
        }
    }

    cleanSelectFile() {
        document.getElementById('dropzone-file').value = ""
        document.getElementById('bloc_dropzone').classList.remove('hidden')
        document.getElementById('block_file').classList.add('hidden')
    }

    importSecteurs() {
        const AgenceAPI = process.env.REACT_APP_AgenceAPI
        const tokenJWT = this.props.accessToken
        const headers = ['codepostal', 'id_agence', 'activite'];
        const listeJson = this.state.secteursToImport
        const env = this
        console.log(listeJson)
        const departements = document.getElementById('select_departements_import').value
        if (listeJson && departements) {

            // verifier que les codes postaux corresponde correpond au departement choisie 
            const pattern = new RegExp('^' + departements); // expression régulière qui vérifie si la chaîne commence par la valeur de la variable
            const result = listeJson.every((json) =>
                headers.every((header) => json.hasOwnProperty(header)) &&
                pattern.test(json.codepostal)
            );

            // verifier que l'assaciation entre codepostal et activite sont unique 
            const associations = {};
            let estUnique = true;
            listeJson.forEach((element) => {
                const codepostal = element.codepostal;
                const activite = element.activite;

                const cleAssociation = `${codepostal}-${activite}`;

                if (cleAssociation in associations) {
                    estUnique = false;
                    return;
                }

                associations[cleAssociation] = true;
            });

            //suite envoie des donnes
            if (result, estUnique) {
                console.log('import', this.state.secteursToImport, departements)
                var config = {
                    method: 'put',
                    url: AgenceAPI + '/api/secteurs/import/' + departements,
                    headers: {
                        'Authorization': 'Bearer ' + tokenJWT,
                        'Content-Type': 'application/json'
                    },
                    data: { secteursToImport: this.state.secteursToImport }
                };
                var message = {
                    401: "Les informations transmises présentent une ou des erreurs.",
                    403: "Les informations transmises présentent une ou des erreurs.",
                    500: 'Les informations transmises présentent une ou des erreurs.'
                }

                axios(config)
                    .then(function (response) {
                        if (response.status == 200) {
                            env.cleanSelectFile()
                            env.setState({
                                importSucces: true
                            })
                        } else {
                            env.displayMessageErrorImport(message[response.status] || "une erreur est survenue")
                        }
                    })
                    .catch(function (error) {
                        console.log("une erreur est survenue", error)
                        env.displayMessageErrorImport(message[error.status] || "une erreur est survenue")
                    });

            } else {
                this.displayMessageErrorImport("Fichier non valide, vérifiez que les colonnes codepostal, id_agence, libellé et activité sont présentes. Vérifiez également que les codes postaux que vous souhaitez intégrer appartiennent au département choisi et que l'association entre le code postal et l'activité est unique.")
            }
        } else {
            if (!departements) {
                this.displayMessageErrorImport("Sélectionner un département")
            } else if (!listeJson) {
                this.displayMessageErrorImport("Sélectionner un fichier csv ou excel")
            } else {
                this.displayMessageErrorImport("Erreur")
            }

        }

    }

    convertCsvToJson() {
        const reader = new FileReader();
        const env = this
        var XLSX = require("xlsx")

        const file = document.getElementById('dropzone-file').files[0]

        reader.onload = function (event) {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });

            const sheet_name_list = workbook.SheetNames;
            const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
            env.setState({
                secteursToImport: json_data
            })
        };

        reader.readAsBinaryString(file);
        document.getElementById('name_file').innerHTML = file.name
        document.getElementById('bloc_dropzone').classList.add('hidden')
        document.getElementById('block_file').classList.remove('hidden')
    }

    dropHandler(ev) {
        ev.preventDefault();
        document.getElementById('dropzone-file').files = ev.dataTransfer.files;
        this.convertCsvToJson()
    }

    closePopUp(type) {
        document.body.classList.remove('overflow-hidden')
        document.getElementById('popup_' + type).classList.add('hidden')
        if (type == "import") {
            this.setState({
                importSucces: false
            })
        }
    }

    openPopUp(type) {
        document.body.classList.add('overflow-hidden')
        document.getElementById('popup_' + type).classList.remove('hidden')
        if (type == "import") {
            this.setState({
                importSucces: false
            })
        }
    }

    displayMessageErrorImport(message) {
        const env = this
        env.setState({
            messageErrorInput: message
        })
        setTimeout(function () {
            env.setState({
                messageErrorInput: null
            })
        }, 10000);
    }

    render() {
        return (
            <>
                <div className='flex ml-auto space-x-3'>

                    {this.state.dataUser.roles.some(role => ['ROLE_AGENCE:superadministrateur', 'ROLE_AGENCE:administrateur', 'ROLE_AGENCE:modificateur'].includes(role))
                        ? <button onClick={() => this.openPopUp("import")} className="group mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded"><FontAwesomeIcon icon={faFileImport} className="mr-1 group-hover:animate-pulse" />Import secteur</button>
                        : null}
                    <button onClick={() => this.openPopUp("export")} className="group mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded"><FontAwesomeIcon icon={faFileExport} className="mr-1 group-hover:animate-pulse" />Export secteur</button>
                </div>

                {/*----------------------------------------------------------------------------- Import -----------------------------------------------------------------------------*/}
                <div id="popup_import" class="min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div onClick={() => this.closePopUp("import")} class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="w-full flex flex-col max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                        <div class="text-center p-5 flex-auto justify-center">
                            <svg class="w-16 h-16 flex items-center text-green-600 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <FontAwesomeIcon icon={faFileExcel} />
                            </svg>
                            <h2 class="text-xl font-bold py-4 ">Importer votre excel.</h2>
                            <p class="text-sm text-gray-500 px-8">En effectuer cette opération l'intégralité des code postal du departement de vous avez choisie présent dans la base de donnes seront écrasé et remplacé par ceux fournies. Ce processus est irréversible.</p>
                        </div>

                        {this.state.importSucces ?
                            <div className='flex flex-col '>
                                <NotifBar message="Les secteur ont été mis à jour" color="green" />
                                <div class="p-3  mt-2 text-center space-x-4 md:block">
                                    <button onClick={() => this.closePopUp("import")} class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                        Fermer
                                    </button>
                                </div>
                            </div>
                            : <div className='flex flex-col space-y-5'>


                                {this.state.messageErrorInput != null ? <span id='messageErrorInput' class='mx-auto text-red-500'>{this.state.messageErrorInput} </span> : null}
                                <div className='flex flex-col'>
                                    <div className='mx-auto h-6 w-6 mb-1'>
                                        <span class="text-[8px] absolute text-blue-500 hover:text-blue-600 cursor-pointer group ">
                                            <div class='hidden group-hover:block absolute text-xs text-black bg-gray-200 rounded-md py-1 px-2 cursor-default bottom-[90%] left-4 w-96 text-justify '>
                                                Vérifiez que les colonnes codepostal, id_agence, libellé et activité sont présentes. Vérifiez également que les codes postaux que vous souhaitez intégrer appartiennent au département choisi et que l'association entre le code postal et l'activité est unique.
                                            </div>
                                            <div className='flex items-center justify-center h-6 w-6 rounded-full border-2 border-blue-500 hover:border-blue-500 text-base '>
                                                <FontAwesomeIcon icon={faInfo} />
                                            </div>
                                        </span>
                                    </div>
                                    <select id="select_departements_import" name="departements" className='cursor-pointer w-1/2 mx-auto bg-gray-200 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8'>
                                        <option value="">-- Choisissez un département --</option>
                                        {this.state.departements.map((departement, key) => (
                                            <option key={key} value={departement.code}>
                                                {departement.code}: {departement.nom}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                                <label for="dropzone-file" id='bloc_dropzone' onDrop={this.dropHandler} onDragOver={(e) => e.preventDefault()} class="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed bg-gray-50 hover:border-gray-300 hover:bg-gray-100 bg-white p-6 text-center">
                                    <p class="mt-2 text-gray-500 tracking-wide">Cliquer pour parcourir ou glisser votre document excel. </p>
                                    <input id="dropzone-file" type="file" class="hidden" onChange={this.convertCsvToJson} accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                </label>

                                <div id='block_file' class=" rounded-md bg-[#F5F7FB] py-4 px-8 hidden">
                                    <div class="flex items-center justify-between">
                                        <span id='name_file' class="truncate pr-3 text-base font-medium text-[#07074D]">
                                            banner-design.png
                                        </span>
                                        <button class="text-[#07074D] " onClick={this.cleanSelectFile}>
                                            <svg className='w-6 h-6'>
                                                <FontAwesomeIcon icon={faXmark} />
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div class="p-3  mt-2 text-center space-x-4 md:block">
                                    <button onClick={() => this.closePopUp("import")} class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                        Annuler
                                    </button>
                                    <button onClick={this.importSecteurs} class="mb-2 md:mb-0 bg-red-500  bg-gray-700 hover:bg-gray-800 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-red-600">
                                        Importer
                                    </button>
                                </div>
                            </div>}
                    </div>
                </div>

                {/*----------------------------------------------------------------------------- Export -----------------------------------------------------------------------------*/}
                <div id="popup_export" class="min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div onClick={() => this.closePopUp("export")} class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="w-full flex flex-col max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                        <div class="text-center p-5 flex-auto justify-center">
                            <svg class="w-16 h-16 flex items-center text-green-600 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <FontAwesomeIcon icon={faFileExcel} />
                            </svg>
                            <h2 class="text-xl font-bold py-4 ">Export secteur d'activité.</h2>
                            <p class="text-sm text-gray-500 px-8">Sélectionnez le département que vous souhaitez exporter.</p>
                        </div>
                        <form onSubmit={this.exportSecteurs} className="flex flex-col">
                            <select name="departements" className='cursor-pointer w-1/2 mx-auto bg-gray-200 shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8'>
                                <option value="">-- Choisissez un département --</option>
                                {this.state.departements.map((departement, key) => (
                                    <option key={key} value={departement.code}>
                                        {departement.code}: {departement.nom}
                                    </option>
                                ))}
                            </select>

                            <div id='block_file' class="my-5 rounded-md bg-[#F5F7FB] py-4 px-8 hidden">
                                <div class="flex items-center justify-between">
                                    <span id='name_file' class="truncate pr-3 text-base font-medium text-[#07074D]">
                                        banner-design.png
                                    </span>
                                    <button class="text-[#07074D] " onClick={this.cleanSelectFile}>
                                        <svg className='w-6 h-6'>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div class="p-3  mt-2 text-center space-x-4 md:block flex">
                                <span onClick={() => this.closePopUp("export")} class="cursor-pointer mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                    Fermer
                                </span>
                                <button class="mb-2 md:mb-0 bg-red-500  bg-gray-700 hover:bg-gray-800 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-red-600">
                                    Exporter
                                </button>
                            </div>
                        </form>
                    </div>


                </div>
            </>
        );
    }
}

export default (props) => (
    <ImportExport
        {...props}
        navigation={useNavigate()}
    />
);