import React, { Component } from 'react';
import axios from 'axios';

class InputPrix extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: [],
            dataProductPrice: this.props.dataProductPrice
        }
        this.onCheck = this.onCheck.bind(this)
        this.setData = this.setData.bind(this)

    }

    componentDidMount() {
        var env = this
        var EcommAPI = process.env.REACT_APP_EcommAPI
        var tokenJWT = this.props.accessToken
        var checkTemp = []

        var config = {
            method: 'get',
            url: EcommAPI + '/api/grilles',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT,
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(async function (response) {
                await response.data.map(async data => {

                    if (env.state.dataProductPrice) {
                        var defaultValue = null
                        await env.state.dataProductPrice.map(tarif => {
                            if (data.nom == tarif.grille.nom) {
                                defaultValue = tarif.ht
                            }
                        })

                        if (defaultValue != null) {
                            checkTemp.push({
                                id: data.id,
                                name: data.nom,
                                value: "on",
                                defaultValue: defaultValue
                            })
                        } else {
                            checkTemp.push({
                                id: data.id,
                                name: data.nom,
                                value: "off"
                            })
                        }
                    } else {
                        checkTemp.push({
                            id: data.id,
                            name: data.nom,
                            value: "off"
                        })
                    }
                })
                env.setState({
                    check: checkTemp
                })
                await env.setData()
            })
    }

    async setData() {
        var dataSet = []

        await this.state.check.map(check => {
            if (check.value == "on") {
                dataSet.push({
                    id: check.id,
                    ht: document.getElementById('inputPrix' + check.name).value
                })
            }

        })
        this.setState({
            valueInputPrix: JSON.stringify(dataSet)
        })
    }

    onCheck(event, nom) {
        let tempCheck = this.state.check
        tempCheck.map(check => {
            if (check.name == nom) {
                check.value = event.target.value == 'on' ? 'off' : 'on'
            }
        })

        this.setState({
            check: tempCheck
        })
        document.getElementById("inputPrix" + nom).disabled = event.target.value == 'on' ? true : false
        this.setData()
    }


    render() {
        return (
            <div class="divide-y divide-gray-200">
                <input name='prix' id='prix' value={this.state.valueInputPrix} type="hidden"></input>
                <div class="  sm:py-5">
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Prix par location
                    </p>
                </div>
                <div class="  sm:px-5 space-y-4 sm:py-5">
                    {
                        this.state.check.map(check =>

                            <div class="flex  w-full">
                                <label for={"prix" + check.name} class="flex text-sm font-medium text-gray-700 sm:mt-px w-1/3">
                                    <span class="w-1/3"> {check.name} </span>
                                    {check.value == 'off'
                                        ? <input class="mx-5 my-auto px-2" onClick={(event) => this.onCheck(event, check.name)} type="checkbox" value={check.value} id={"checkbox" + check.name} />
                                        : <input class="mx-5 my-auto px-2" checked onClick={(event) => this.onCheck(event, check.name)} type="checkbox" value={check.value} id={"checkbox" + check.name} />
                                    }

                                </label>

                                {check.value == 'off'
                                    ? <input disabled required step=".01" onChange={this.setData} type="number" min="0" id={"inputPrix" + check.name} class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8 px-2" />
                                    : <input required step=".01" onChange={this.setData} defaultValue={check.defaultValue} type="number" min="0" id={"inputPrix" + check.name} class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8 px-2" />
                                }

                            </div>
                        )
                    }


                </div>
            </div>
        )
    }
}

export default InputPrix;