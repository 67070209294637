import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faFileImport, faFileExcel, faXmark } from '@fortawesome/free-solid-svg-icons'
import NotifBar from '../../components/notifBar'

class ImportExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            where: this.props.where,
            dataUser: this.props.dataUser,
            messageErrorInput: null,
            importSucces: false
        }
        this.convertCsvToJson = this.convertCsvToJson.bind(this)
        this.importPrices = this.importPrices.bind(this)
        this.dropHandler = this.dropHandler.bind(this)
        this.openPopUp = this.openPopUp.bind(this)
        this.closePopUp = this.closePopUp.bind(this)
        this.displayMessageErrorImport = this.displayMessageErrorImport.bind(this)
    }

    exportPrices() {
        var EcommAPI = process.env.REACT_APP_EcommAPI
        var tokenJWT = this.props.accessToken

        var config = {
            method: 'get',
            url: EcommAPI + '/api/produits/exportPrices',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT
            }
        };

        axios(config).then(response => {
            /* this.setState({
                 dataTable: response.data
             });*/
            console.log(response.data)
            var headerClean = ['id', 'nom', 'modele', 'marque', 'tarif_ht_1', 'secteur_1', 'tarif_ht_2', 'secteur_2']
            this.convertJsonToCsv(response.data, 'Export_product_prices', null, headerClean)
        })

    }

    recupererHeaders(val, array = [], parent = null) {
        for (var j in val) {
            if (typeof val[j] == 'object') {
                if (Array.isArray(val[j])) {
                    val[j].map((vals, key) => {
                        this.recupererHeaders(vals, array, (parent ? parent + "." : "") + j + "[" + key + "]")
                    })
                } else {
                    this.recupererHeaders(val[j], array, (parent ? parent + "." : "") + j)
                }
            } else {
                array.push(parent ? parent + "." + j : j)
            }
        }
        return array
    }

    convertJsonToCsv(jsonTable, fileName, header = null, headerClean = null) {
        var items = jsonTable
        var replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        try {
            var arr = jsonTable.map(vals => JSON.stringify(vals).split('":"').length)
            var key = arr.indexOf(Math.max.apply(null, arr))
            header = header == null ? this.recupererHeaders(jsonTable[key]) : header

            console.log(header)

            var csv = [
                headerClean == null ? header.join(';') : headerClean.join(';'), // header row first
                ...items.map(row => header.map((fieldName) => { try { return JSON.stringify(eval("row." + fieldName), replacer) } catch (e) { return null } }).join(';'))
            ].join('\n')
        } catch (error) {
            console.log(error)
            //this.displayAlert("Une erreur est survenue lorsque de la conversion des données", "red")
            //document.getElementById('spinner').classList.add("hidden")
        }

        this.downloadString(csv, "text/csv", fileName)
    }

    downloadString(text, fileType, fileName) {
        try {
            var blob = new Blob([text], { type: fileType });
            var a = document.createElement('a');
            a.download = fileName;
            a.href = URL.createObjectURL(blob);
            a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () { URL.revokeObjectURL(a.href); }, 1500);
            //document.getElementById('spinner').classList.add("hidden")
        } catch (error) {
            console.log(error)
            //this.displayAlert("Une erreur est survenue lorsque de l'envoie des données", "red")
            //document.getElementById('spinner').classList.add("hidden")
        }
    }

    cleanSelectFile() {
        document.getElementById('dropzone-file').value = ""
        document.getElementById('bloc_dropzone').classList.remove('hidden')
        document.getElementById('block_file').classList.add('hidden')
    }

    importPrices() {
        console.log('import', this.state.pricesToImport)
        const EcommAPI = process.env.REACT_APP_EcommAPI
        const tokenJWT = this.props.accessToken
        const headers = ["id", "modele", "tarif_ht_1", "secteur_1"];
        const listeJson = this.state.pricesToImport
        const env = this

        var message = {
            401: "Les informations transmises présentent une ou des erreurs.",
            403: "Les informations transmises présentent une ou des erreurs.",
            500: 'Les informations transmises présentent une ou des erreurs.'
        }

        if (listeJson) {
            const result = listeJson.every((json) =>
                headers.every((header) => json.hasOwnProperty(header))
            );

            if (result) {
                console.log('import', this.state.pricesToImport)
                var config = {
                    method: 'put',
                    url: EcommAPI + '/api/produits/importPrices',
                    headers: {
                        'Authorization': 'Bearer ' + tokenJWT,
                        'Content-Type': 'application/json'
                    },
                    data: { pricesToImport: this.state.pricesToImport }
                };

                axios(config)
                    .then(function (response) {
                        if (response.status == 200) {
                            env.cleanSelectFile()
                            env.setState({
                                importSucces: true
                            })
                        } else {
                            env.displayMessageErrorImport(message[response.status] || "une erreur est survenue")
                        }
                    })
                    .catch(function (error) {
                        console.log("une erreur est survenue", error)
                        env.displayMessageErrorImport("une erreur est survenue")
                    });

            } else {
                this.displayMessageErrorImport("Fichier non valide, vérifier que les colonnes id, modele, tarif_ht_1, secteur_1 sont présentent.")
            }
        }
        else {
            this.displayMessageErrorImport("Sélectionner un fichier csv ou excel")
        }
    }

    convertCsvToJson() {
        const reader = new FileReader();
        const env = this
        var XLSX = require("xlsx")

        const file = document.getElementById('dropzone-file').files[0]

        reader.onload = function (event) {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });

            const sheet_name_list = workbook.SheetNames;
            const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
            env.setState({
                pricesToImport: json_data
            })
        };

        reader.readAsBinaryString(file);
        document.getElementById('name_file').innerHTML = file.name
        document.getElementById('bloc_dropzone').classList.add('hidden')
        document.getElementById('block_file').classList.remove('hidden')


    }

    dropHandler(ev) {
        ev.preventDefault();
        document.getElementById('dropzone-file').files = ev.dataTransfer.files;
        this.convertCsvToJson()
    }

    openPopUp() {
        document.getElementById('popup_import').classList.remove('hidden')
        document.body.classList.add('overflow-hidden')
        this.setState({
            importSucces: false
        })
    }

    closePopUp() {
        document.getElementById('popup_import').classList.add('hidden')
        document.body.classList.remove('overflow-hidden')
        this.setState({
            importSucces: false
        })
    }

    displayMessageErrorImport(message) {
        const env = this
        env.setState({
            messageErrorInput: message
        })
        setTimeout(function () {
            env.setState({
                messageErrorInput: null
            })
        }, 3000);
    }

    render() {
        return (
            <>
                <div className='flex ml-auto space-x-3'>
                    <button onClick={() => this.exportPrices()} className="group mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded"><FontAwesomeIcon icon={faFileExport} className="mr-1 group-hover:animate-pulse" />Export prix</button>

                    {this.state.dataUser.roles.includes('ROLE_AGENCE:superadministrateur') ||
                        this.state.dataUser.roles.includes('ROLE_ECOMM:administrateur') ||
                        this.state.dataUser.roles.includes('ROLE_ECOMM:modificateur')
                        ? <button onClick={() => this.openPopUp()} className="group mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded"><FontAwesomeIcon icon={faFileImport} className="mr-1 group-hover:animate-pulse" />Import prix</button>
                        : null}
                </div>
                <div id="popup_import" class="min-w-screenh-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover hidden"  >
                    <div onClick={this.closePopUp} class="absolute bg-black opacity-80 inset-0 z-0"></div>
                    <div class="w-full flex flex-col max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                        <div class="text-center p-5 flex-auto justify-center">
                            <svg class="w-16 h-16 flex items-center text-green-600 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <FontAwesomeIcon icon={faFileExcel} />
                            </svg>
                            <h2 class="text-xl font-bold py-4 ">Importer votre excel.</h2>
                            <p class="text-sm text-gray-500 px-8">En effectuer cette opération l'intégralité des prix présent dans la base de donnes seront écrasé et remplacé par ceux fournies. Ce processus est irréversible.</p>
                        </div>


                        {this.state.importSucces ?
                            <div className='flex flex-col '>
                                <NotifBar message="Les prix ont été mises à jour" color="green" />
                                <div class="p-3  mt-2 text-center space-x-4 md:block">
                                    <button onClick={this.closePopUp} class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                        Fermer
                                    </button>
                                </div>
                            </div>
                            : <div className='flex flex-col'>
                                {this.state.messageErrorInput != null ? <span id='messageErrorInput' class='mx-auto text-red-500'>{this.state.messageErrorInput} </span> : null}

                                <label for="dropzone-file" id='bloc_dropzone' onDrop={this.dropHandler} onDragOver={(e) => e.preventDefault()} class="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed bg-gray-50 hover:border-gray-300 hover:bg-gray-100 bg-white p-6 text-center">
                                    <p class="mt-2 text-gray-500 tracking-wide">Cliquer pour parcourir ou glisser votre document excel. </p>
                                    <input id="dropzone-file" type="file" class="hidden" onChange={this.convertCsvToJson} accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                </label>

                                <div id='block_file' class="my-5 rounded-md bg-[#F5F7FB] py-4 px-8 hidden">
                                    <div class="flex items-center justify-between">
                                        <span id='name_file' class="truncate pr-3 text-base font-medium text-[#07074D]">
                                            banner-design.png
                                        </span>
                                        <button class="text-[#07074D] " onClick={this.cleanSelectFile}>
                                            <svg className='w-6 h-6'>
                                                <FontAwesomeIcon icon={faXmark} />
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div class="p-3  mt-2 text-center space-x-4 md:block">
                                    <button onClick={this.closePopUp} class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                        Annuler
                                    </button>
                                    <button onClick={this.importPrices} class="mb-2 md:mb-0 bg-red-500  bg-gray-700 hover:bg-gray-800 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-red-600">
                                        Importer
                                    </button>
                                </div>
                            </div>}

                    </div>

                </div>
            </>
        );
    }
}

export default (props) => (
    <ImportExport
        {...props}
        navigation={useNavigate()}
    />
);