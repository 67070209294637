import React, { Component } from 'react';

export default class LoadingBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maxWidth: this.props.maxWidth || null,
            height: this.props.height || "h-5",
        }
    }

    render() {

        return (
            <span className={`block bg-gray-500 animate-pulse w-full rounded-sm + ${this.state.height} + ${this.state.maxWidth}`}></span>
        );
    }
}