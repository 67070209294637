import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'

export default function ButtonBack() {
    const navigate = useNavigate();

    function handleRetour() {
        navigate(-1);
    }

    return (
        <button onClick={handleRetour} className="group mt-1 mb-auto text-white right-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 font-medium text-sm px-2 py-1 cursor-pointer rounded"><FontAwesomeIcon icon={faCaretLeft} className="mr-1 group-hover:animate-pulse" />Retour</button>
    );
}