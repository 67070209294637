import React, { Component } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from "moduleauthazureapp";
import { Navigate } from "react-router-dom";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return this.props.isAuthenticated
            ? <Navigate replace to="/home" />
            : <div className="h-screen flex  w-full">
                <div className="mx-auto flex w-1/2 rounded my-auto">
                    <img className='w-2/5  rounded-tl rounded-bl ' src={"/images/login.png"}></img>
                    <div className='flex flex-col w-3/5 bg-dark text-white rounded-tr rounded-br'>
                        <SignInButton >
                            <button className="bg-blue-500 hover:bg-blue-500 font-bold rounded cursor-pointer max-w-xs flex w-[25vw] mx-auto my-auto" >
                                <div className="px-6 py-3 border-r border-zinc-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 my-auto " viewBox="0 0 128 128">
                                        <g>
                                            <path id="path1" transform="rotate(0,64,64) translate(0,0.00014495823688776) scale(3.99999284745542,3.99999284745542)  " fill="#FFFFFF" d="M13.908992,16.207977L32.000049,16.207977 32.000049,31.999985 13.908992,30.109983z M0,16.207977L11.904009,16.207977 11.904009,29.900984 0,28.657984z M11.904036,2.0979624L11.904036,14.202982 2.7656555E-05,14.202982 2.7656555E-05,3.3409645z M32.000058,0L32.000058,14.203001 13.909059,14.203001 13.909059,1.8890382z" />
                                        </g>
                                    </svg>
                                </div>
                                <span className="my-auto mx-auto">Connexion avec Microsoft </span>
                            </button>
                        </SignInButton>
                    </div>
                </div>
            </div>
    }
}

export default (props) => (
    <Login
        {...props}
        isAuthenticated={useIsAuthenticated()}
    />
);
