import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faXmark, faInfo } from '@fortawesome/free-solid-svg-icons'
import NotifBar from '../notifBar'

class CleanModelMarkByCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            where: this.props.where,
            dataUser: this.props.dataUser,
            messageErrorInputCleanAdress: null,
            importSucces: false,

        }
        this.convertCsvToJson = this.convertCsvToJson.bind(this)
        this.dropHandler = this.dropHandler.bind(this)
        this.displayMessageErrorImport = this.displayMessageErrorImport.bind(this)
        this.cleanCustomer = this.cleanCustomer.bind(this)
    }



    recupererHeaders(val, array = [], parent = null) {
        for (var j in val) {
            if (typeof val[j] == 'object') {
                if (Array.isArray(val[j])) {
                    val[j].map((vals, key) => {
                        this.recupererHeaders(vals, array, (parent ? parent + "." : "") + j + "[" + key + "]")
                    })
                } else {
                    this.recupererHeaders(val[j], array, (parent ? parent + "." : "") + j)
                }
            } else {
                array.push(parent ? parent + "." + j : j)
            }
        }
        return array
    }

    convertJsonToCsv(jsonTable, fileName, header = null, headerClean = null) {
        var items = jsonTable
        var replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        try {
            var arr = jsonTable.map(vals => JSON.stringify(vals).split('":"').length)
            var key = arr.indexOf(Math.max.apply(null, arr))
            header = header == null ? this.recupererHeaders(jsonTable[key]) : header

            var csv = [
                headerClean == null ? header.join(';') : headerClean.join(';'), // header row first
                ...items.map(row => header.map((fieldName) => { try { return JSON.stringify(eval("row." + fieldName), replacer) } catch (e) { return null } }).join(';'))
            ].join('\n')
        } catch (error) {
            console.log(error)
            //this.displayAlert("Une erreur est survenue lorsque de la conversion des données", "red")
            //document.getElementById('spinner').classList.add("hidden")
        }

        this.downloadString(csv, "text/csv", fileName)
    }

    downloadString(text, fileType, fileName) {
        try {
            var blob = new Blob([text], { type: fileType });
            var a = document.createElement('a');
            a.download = fileName;
            a.href = URL.createObjectURL(blob);
            a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () { URL.revokeObjectURL(a.href); }, 1500);
            //document.getElementById('spinner').classList.add("hidden")
        } catch (error) {
            console.log(error)
            //this.displayAlert("Une erreur est survenue lorsque de l'envoie des données", "red")
            //document.getElementById('spinner').classList.add("hidden")
        }
    }

    cleanSelectFile() {
        document.getElementById('dropzone_file_cleanModelMark').value = ""
        document.getElementById('bloc_dropzone_cleanModelMark').classList.remove('hidden')
        document.getElementById('block_file_cleanModelMark').classList.add('hidden')
    }

    convertCsvToJson() {
        const reader = new FileReader();
        const env = this
        var XLSX = require("xlsx")

        const file = document.getElementById('dropzone_file_cleanModelMark').files[0]

        reader.onload = function (event) {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });

            const sheet_name_list = workbook.SheetNames;
            const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
            env.setState({
                json_data: json_data
            })
        };

        reader.readAsBinaryString(file);
        document.getElementById('name_file_cleanModelMark').innerHTML = file.name
        document.getElementById('bloc_dropzone_cleanModelMark').classList.add('hidden')
        document.getElementById('block_file_cleanModelMark').classList.remove('hidden')
    }

    cleanCustomer() {
        const headers = ['N_Client', 'Marque', 'Modele'];
        const listeJson = this.state.json_data
        if (listeJson) {

            // qu'il les bon en tête
            const result = listeJson.every((json) =>
                headers.every((header) => json.hasOwnProperty(header))
            );

            //suite envoie des donnes
            if (result) {
                const list2 = [];

                listeJson.forEach(item => {
                    const existingClient = list2.find(client => client["N_Client"] === item["N_Client"]);

                    if (existingClient) {
                        existingClient.appareil.push({ Marque: item.Marque, Modele: item.Modele });
                    } else {
                        list2.push({
                            "N_Client": item["N_Client"],
                            appareil: [{ Marque: item.Marque, Modele: item.Modele }]
                        });
                    }
                });

                this.convertJsonToCsv(list2, "client_Netoyer")

            } else {
                this.displayMessageErrorImport("Fichier non valide, vérifiez que les colonnes codepostal, id_agence, libellé et activité sont présentes.")
            }
        } else {
            this.displayMessageErrorImport("Sélectionner un fichier csv ou excel")

        }

    }


    dropHandler(ev) {
        ev.preventDefault();
        document.getElementById('dropzone_file_cleanModelMark').files = ev.dataTransfer.files;
        this.convertCsvToJson()
    }


    displayMessageErrorImport(message) {
        const env = this
        env.setState({
            messageErrorInputCleanModelMark: message
        })
        setTimeout(function () {
            env.setState({
                messageErrorInputCleanModelMark: null
            })
        }, 10000);
    }

    render() {
        return (
            <>
                <div class="w-full flex flex-col max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                    <div class="text-center p-5 flex-auto justify-center">
                        <svg class="w-16 h-16 flex items-center text-green-600 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                            <FontAwesomeIcon icon={faFileExcel} />
                        </svg>
                        <h2 class="text-xl font-bold py-4 ">Netoyer vos client</h2>
                        <p class="text-sm text-gray-500 px-8">Rassemble les modeles et les marques par client</p>
                    </div>

                    {this.state.importSucces ?
                        <div className='flex flex-col '>
                            <NotifBar message="Les secteur ont été mis à jour" color="green" />
                        </div>
                        : <div className='flex flex-col space-y-5'>


                            {this.state.messageErrorInputCleanAdress != null ? <span id='messageErrorInputCleanAdress' class='mx-auto text-red-500'>{this.state.messageErrorInputCleanAdress} </span> : null}
                            <div className='flex flex-col'>
                                <div className='mx-auto h-6 w-6 mb-1'>
                                    <span class="text-[8px] absolute text-blue-500 hover:text-blue-600 cursor-pointer group ">
                                        <div class='hidden group-hover:block absolute text-xs text-black bg-gray-200 rounded-md py-1 px-2 cursor-default bottom-[90%] left-4 w-96 text-justify '>
                                            Vérifiez que les colonnes N_Client, Marque, Modele.
                                        </div>
                                        <div className='flex items-center justify-center h-6 w-6 rounded-full border-2 border-blue-500 hover:border-blue-500 text-base '>
                                            <FontAwesomeIcon icon={faInfo} />
                                        </div>
                                    </span>
                                </div>
                            </div>


                            <label for="dropzone_file_cleanModelMark" id='bloc_dropzone_cleanModelMark' onDrop={this.dropHandler} onDragOver={(e) => e.preventDefault()} class="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center rounded-xl border-2 border-dashed bg-gray-50 hover:border-gray-300 hover:bg-gray-100 bg-white p-6 text-center">
                                <p class="mt-2 text-gray-500 tracking-wide">Cliquer pour parcourir ou glisser votre document excel. </p>
                                <input id="dropzone_file_cleanModelMark" type="file" class="hidden" onChange={this.convertCsvToJson} accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                            </label>

                            <div id='block_file_cleanModelMark' class=" rounded-md bg-[#F5F7FB] py-4 px-8 hidden">
                                <div class="flex items-center justify-between">
                                    <span id='name_file_cleanModelMark' class="truncate pr-3 text-base font-medium text-[#07074D]">
                                        banner-design.png
                                    </span>
                                    <button class="text-[#07074D] " onClick={this.cleanSelectFile}>
                                        <svg className='w-6 h-6'>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div class="p-3  mt-2 text-center space-x-4 md:block">
                                <button onClick={this.cleanCustomer} class="mb-2 md:mb-0 bg-red-500  bg-gray-700 hover:bg-gray-800 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-red-600">
                                    Netoyer
                                </button>
                            </div>
                        </div>}
                </div>


            </>
        );
    }
}

export default (props) => (
    <CleanModelMarkByCustomer
        {...props}
        navigation={useNavigate()}
    />
);