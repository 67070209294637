import React from 'react';
import Navbar from '../../components/navbar';
import Profilebar from "../../components/profilebar"
import FormAddProducts from '../../components/m-energies-solutions/formAddProducts';
import ButtonBack from "../../components/buttonBack"
import NotifBar from '../../components/notifBar'
import { useParams, useNavigate } from "react-router-dom";

import axios from 'axios';
import formClim from '../../assets/form/form_clim.json'
import formPac from '../../assets/form/form_pac.json'
import formChaudiere from '../../assets/form/form_chaudiere.json'
import formAdoucisseur from '../../assets/form/form_adoucisseur.json'
import formAntitartres from '../../assets/form/form_antitartres.json'
import formChaudiereBois from '../../assets/form/form_chaudierebois.json'
import formCumulus from '../../assets/form/form_cumulus.json'
import formDesembouages from '../../assets/form/form_desembouages.json'
import formPoelepelet from '../../assets/form/form_poelepelet.json'
import formPots from '../../assets/form/form_pots.json'
import formReguls from '../../assets/form/form_reguls.json'
import formThermodynamique from '../../assets/form/form_thermodynamique.json'

class AddProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            EcommAPI: process.env.REACT_APP_EcommAPI,
            tokenJWT: this.props.accessToken,
            dataUser: this.props.dataUser,
            form: 0
        }
        this.send = this.send.bind(this)
    }

    componentDidMount() {
        this.getFrom(this.props.params)
    }

    async getFrom(params) {
        const EcommAPI = process.env.REACT_APP_EcommAPI
        const tokenJWT = this.props.accessToken
        const getFrom = {
            'climatiseur': formClim,
            'pompe-a-chaleur': formPac,
            'chaudiere-gaz-fioul': formChaudiere,
            'adoucisseur': formAdoucisseur,
            'antitartres': formAntitartres,
            'chaudiere-a-bois': formChaudiereBois,
            'cumulus': formCumulus,
            'desembouages': formDesembouages,
            'poele-a-pellet': formPoelepelet,
            'pots': formPots,
            'reguls': formReguls,
            'thermodynamique': formThermodynamique
        };

        var config = {
            method: 'get',
            url: EcommAPI + '/api/marques',
            headers: {
                'Authorization': 'Bearer ' + tokenJWT,
                'Content-Type': 'application/json'
            }
        };
        var arrayMarque = []



        let updatedForm = getFrom[params.categoryProduct]

        if (!updatedForm) {
            this.props.navigation('/404')
        } else {
            await axios(config)
                .then(function (response) {
                    response.data.map(marque => {

                        arrayMarque.push({
                            "value": marque.id,
                            "description": marque.nom
                        })
                    })

                })

            await updatedForm.group.map(group => {
                group.input.map(input => {
                    if (input.name == "marque") {
                        input.option = arrayMarque
                    }
                })
            })

            this.setState({
                form: updatedForm
            })
        }


    }

    displayAlert(messageAlert) {
        this.setState({
            displayAlert: !this.state.displayAlert,
            messageAlert: messageAlert
        })
        setTimeout(() => {
            this.setState({
                displayAlert: !this.state.displayAlert
            })
        }, 6000);
    }

    async send(data) {
        data = data.replaceAll('"[', '[')
        data = data.replaceAll(']"', ']')
        data = data.replaceAll('\\"', '"')
        console.log(data.replaceAll('"[', '['))
        var env = this

        var config = {
            method: 'post',
            url: this.state.EcommAPI + '/api/produits',
            headers: {
                'Authorization': 'Bearer ' + this.state.tokenJWT,
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios(config)
            .then(function (response) {
                window.location.replace("/tableau-des-produits/" + env.state.params.categoryProduct)
            })
            .catch(function (error) {
                env.displayAlert("une erreur est survenue")
                console.log(error)
            });
    }

    render() {
        const getTitre = {
            "climatiseur": "climatiseur",
            "pompe-a-chaleur": "pompe à chaleur",
            "chaudiere-gaz-fioul": "chaudière",
            "adoucisseur": "adoucisseur",
            "chaudiere-a-bois": "chaudière à bois",
            "poele-a-pellet": "poêle à pellet",
            "thermodynamique": "thermodynamique",
            "cumulus": "cumulus",
            "antitartres": "filtre anti-tartre",
            "reguls": "régulateurs",
            "desembouages": "désembouage",
            "pots": "pot de récupération"
        };

        return (
            <>
                <Navbar where={"M-energies Solutions produits"} dataUser={this.props.dataUser} />
                <div className="flex flex-col w-full h-full">
                    <Profilebar username={this.state.dataUser.userName} />
                    <div className=" bg-gray-200 h-full">
                        <div class="transition-colors duration-300 flex justify-between z-20 bg-gray-700  bg-opacity-10 h-[64px] px-10 ">
                            <h3 class="text-gray-[#111827]] text-lg my-auto"> Ajout {getTitre[this.props.params.categoryProduct]} </h3>
                            <div className='flex my-auto space-x-4'>
                                <ButtonBack />
                            </div>
                        </div>
                        <div className="px-10 py-[1vw]">
                            {this.state.form != 0 ?
                                <FormAddProducts jsonForm={this.state.form} processData={this.send} accessToken={this.props.accessToken} ></FormAddProducts>
                                : null
                            }
                        </div>
                    </div>
                </div>
                {this.state.displayAlert ?
                    <div id='notifBar' class="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover "  >
                        <div className='mx-auto h-2/3 my-auto'>
                            <NotifBar message={this.state.messageAlert || null} color="red" />
                        </div>
                    </div>
                    : null}
            </>
        );
    }

};

export default (props) => (
    <AddProducts
        {...props}
        params={useParams()}
        navigation={useNavigate()}
    />
);