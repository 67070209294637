import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { Component } from 'react';

class ErrorPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }

  }

  render() {
    return (
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 m-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20 mb-12">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold  sm:text-5xl">
              401
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  vous n'avez pas les droits
                </h1>
                <p className="mt-1 text-base text-gray-500">
                </p>
              </div>
              <div className="mt-10 flex space-x-1 sm:border-l sm:border-transparent sm:pl-6">
                <NavLink to={'/logout'}
                  className="bg-black hover:bg-gray-800 font-bold py-2 px-4 mb-2 rounded cursor-pointer text-white">
                  Me déconnecter
                </NavLink>
                <NavLink to="/"
                  className="bg-black hover:bg-gray-800 font-bold py-2 px-4 mb-2 rounded cursor-pointer text-white ">
                  Retour à l’accueil
                </NavLink>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default (props) => (
  <ErrorPage
    {...props}
    navigate={useNavigate()}
  />
);
