import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";

class NavAddAgence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            where: this.props.where,
            dataUser: this.props.dataUser
        }
    }


    render() {
        return (
            this.state.dataUser.roles.includes('ROLE_AGENCE:superadministrateur') ||
                this.state.dataUser.roles.includes('ROLE_AGENCE:administrateur') ||
                this.state.dataUser.roles.includes('ROLE_AGENCE:modificateur')
                ?
                <NavLink className={"ml-auto "} to={this.props.path} onClick={() => { if (window.location.pathname == this.props.path) { this.props.navigation(this.props.path); window.location.reload() } }}>
                    <FontAwesomeIcon icon={faPlus} className="hover:animate-pulse text-[0.7vw] my-auto " />
                </NavLink>
                : null
        );
    }
}

export default (props) => (
    <NavAddAgence
        {...props}
        navigation={useNavigate()}
    />
);