import React, { Component } from 'react';

class InputTextarea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: this.props.defaultValue ? this.props.defaultValue.length : 0
        }
        this.updateCounter = this.updateCounter.bind(this)
    }

    updateCounter(e) {
        this.setState({
            counter: e.target.value.length
        })
    }

    render() {
        return (
            <div className='flex flex-col w-2/3'>
                <textarea
                    required={this.props.required ? true : false}
                    rows={this.props.rows}
                    placeholder={this.props.placeholder ? this.props.placeholder : null}
                    defaultValue={this.props.defaultValue}
                    name={this.props.name}
                    id={this.props.id}
                    maxlength={this.props.maxlength || "255"}
                    onChange={this.updateCounter}
                    class="block  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md h-16 px-2 w-full"
                ></textarea>
                <span className='text-gray-400 ml-auto text-sm'> {this.state.counter} / {this.props.maxlength || "255"} </span>
            </div>

        )
    }
}

export default InputTextarea;