import React, { Component } from 'react';
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBell, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import ModuleProfile from './moduleProfile'

class Profilebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username,
            navigation: this.props.navigation
        }
    }

    render() {
        return (
            <>
                <div className="flex navbar-light bg-[#111827] w-full min-h-[64px] max-h-[64px] text-white px-5 ">
                    <div className='flex justify-end py-2.5 space-x-2 ml-auto'>
                        <div className=' flex text-sm my-auto whitespace-nowrap'>
                            {this.state.username}
                        </div>
                        <ModuleProfile displaySecondeProfilBar={this.displaySecondeProfilBar}></ModuleProfile>
                    </div>
                </div>
            </>
        );
    }
}

export default (props) => (
    <Profilebar
        {...props}
        navigation={useNavigate()}
    />
);