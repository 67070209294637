import React, { Component } from 'react';

class InputSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: this.props.input,
            valueOfSelected: this.props.valueOfSelected,
            refresh: 1
        }
    }

    componentDidMount() {
        if (this.state.input.option.length == 0) {
            this.timerID = setInterval(
                () => this.refresh(),
                30
            );
        }

    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    refresh() {
        this.setState({
            refresh: this.state.refresh + 1
        })

        if (this.state.refresh >= 10) {
            clearInterval(this.timerID);
        }

        //console.log(this.state.refresh, this.timerID)

    }

    render() {
        return (
            <select required={this.state.input.required ? true : false} id={this.state.input.name} name={this.state.input.name} class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md h-8">
                {this.state.input.option.map((option) =>
                    <option value={option.value}
                        selected={
                            typeof this.state.valueOfSelected == 'boolean' && this.state.valueOfSelected != null && this.state.valueOfSelected.toString() == option.value ||
                                typeof this.state.valueOfSelected != 'undefined' && this.state.valueOfSelected != null && this.state.valueOfSelected.toString() == option.value ||
                                typeof this.state.valueOfSelected != 'undefined' && this.state.valueOfSelected != null && this.state.valueOfSelected.toString() == option.description
                                ? "selected"
                                : null}
                    > {option.description}</option>
                )}
            </select>
        )
    }
}

export default InputSelect;